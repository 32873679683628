import React from 'react';
import Marquee from 'react-fast-marquee';
import "./mymarquee.css";
import { FaRegBell } from "react-icons/fa";
import { useState,useEffect } from 'react'; 
import axios from "axios";

const MyMarquee = () => {

  
    const [xvalue, setCurrentValue] = useState('');
    const configArray = [{id_name: 'Flash News', id_value: xvalue}];
    const fetchConfigValue = async () => {
      let xmsg = '';
      const today = new Date();
      if (today.getMonth() === 11 && today.getDate() === 25) {
        xmsg='** 🎄இனிய கிறிஸ்துமஸ் திருநாள் வாழ்த்துக்கள்!! HAPPY CHRISTMAS!!🎄**'
      }
      if(today.getMonth() === 0 && today.getDate() === 1){
        xmsg='HAPPY NEW YEAR!!'
      }
      if(today.getMonth() === 0 && today.getDate() === 26){
        xmsg='** இனிய குடியரசு தின நல்வாழ்த்துகள்!! HAPPY REPUBLIC DAY!! **'
      }
      if(today.getMonth() === 7 && today.getDate() === 15){
        xmsg='HAPPY INDEPEDENCE DAY!!'
      }
      if(today.getMonth() === 4 && today.getDate() === 1){
        xmsg='MAY DAY!!'
      }
      if(today.getMonth() === 0 && today.getDate() === 14 && today.getFullYear()===2025){
        xmsg='** இனிய தைப்பொங்கல் நல்வாழ்த்துகள்!! HAPPY THAI PONGAL!! **'
      }
      if(today.getMonth() === 3 && today.getDate() === 14 && today.getFullYear()===2025){
        xmsg='HAPPY NEW YEAR!!'
      }
      if (xmsg==='') {
        try {
            const data = {configArray}
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readConfigData`, data);
            xmsg=response.data[0].id_value;
        }
        catch (error) {
            console.error('Error fetching config value:', error);
        }
      }
      setCurrentValue(xmsg);
    };

    useEffect(() => {
        fetchConfigValue();
    },[]);
    

    return (
        <>
            <div className='mymarquee' >
                <FaRegBell className='bellicon'/> &nbsp; &nbsp;
                <Marquee velocity={25}>
                    <h3>{xvalue}</h3>
                </Marquee>
            </div>
        </>
    );
};

export default MyMarquee;