import React, { useState, useEffect, useRef } from 'react';
import './GSTR2_IMS.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import RainbowPanel from './RainbowPanel';
import { FaSearch } from "react-icons/fa";
import { hover } from '@testing-library/user-event/dist/hover';

const AgeGridForm = () => {
    const [statesData, setStatesData] = useState([]);
    let gstrdata = [{ typeofdata: 'b2b', num: 1, stin: '33ASDASDSDASD45', action: 'A', txval: 1000, srcfilstatus: "Not Filed" },     // dummy
    { typeofdata: 'b2b', num: 2, stin: '33BSDASDSDASD45', action: 'A', txval: 1000, srcfilstatus: "25/12/24" },
    { typeofdata: 'b2b', num: 3, stin: '33CSDASDSDASD45', action: 'A', txval: 2000, srcfilstatus: "Not Filed" },
    { typeofdata: 'b2b', num: 4, stin: '33DSDASDSDASD45', action: 'R', txval: 3000, srcfilstatus: "Not Filed" },
    { typeofdata: 'b2b', num: 5, stin: '33ESDASDSDASD45', action: 'N', txval: 4000, srcfilstatus: "25/12/24" },
    { typeofdata: 'dn', typeofdata: 'b2b', num: 6, stin: '33BSDASDSDASD45', action: 'P', txval: 1000, srcfilstatus: "25/12/24" },
    { typeofdata: 'cn', num: 7, stin: '33BSDASDSDASD45', action: 'P', txval: 1000, srcfilstatus: "Not Filed" },
    ]
    const [existingData, setExistingData] = useState([]);
    const [gstin, setgstin] = useState('');
    const [gstr2ims, setgstr2ims] = useState(gstrdata);

    const [search, setSearch] = useState({ gstin: '', billno: '', billdate: '' });
    const [status, setStatus] = useState('All');
    const navigate = useNavigate();

    const gridRef = useRef(null);
    // const gstin = gstr2ims.gstin;


    const [filePrd, setFilePrd] = useState('');
    const dataobj = ['b2b', 'cn', 'dn', 'All'];
    const filedobj = ["Filed", "Not Filed", "All"];
    const statusobj = [
        { name: 'Accept', value: 'A' },
        { name: 'Pending', value: 'P' },
        { name: 'Reject', value: 'R' },
        { name: 'No Action', value: 'N' },
        { name: 'All', value: 'All' }
    ];
    const [filter, setFilter] = useState({
        typeofdata: 'All', filedtype: 'All', status: 'All'
    });

    const fetchStatedata = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/api/states`);
            setStatesData(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    const fetchDisplayData = (displaydata) => {
        if (!displaydata) {
            return;
        }
        let data = displaydata;
        data = addNum(data);
        setExistingData(data);
    }

    useEffect(() => {
        fetchStatedata();
    }, []);

    useEffect(() => {
        fetchDisplayData(gstr2ims);          //gstrims.imsdata
    }, [gstr2ims]);

    useEffect(() => {
        handleFilterSearchChange(search.gstin, search.billno, search.billdate, filter.typeofdata, filter.filedtype, filter.status);
    }, [search, filter])

    const [api, setApi] = useState(null);

    const onGridReady = (params) => {
        setApi(params.api); // Set API to state
    };

    let filterDisabled = false; // Flag to disable filter temporarily
    let currentFilterModel = {}; // Store the current filter model

    // Save the current filter model before performing any action
    const saveFilterModel = () => {
        currentFilterModel = gridRef.current.api.getFilterModel();
    };

    // const handleFilterStatusChange = (status) => {
    //     if (api) {
    //         alert(1)
    //         const filterModel = {};
    //         if (status && status !== 'All') {
    //             filterModel.action = {
    //                 filterType: "text",
    //                 type: "contains",
    //                 filter: status,
    //             }
    //         }
    //         gridRef.current.api.setFilterModel(filterModel);
    //         gridRef.current.api.onFilterChanged();
    //         setTimeout(() => {
    //             const displayedRowCount = gridRef.current.api.getDisplayedRowCount();
    //             if (displayedRowCount > 0) {
    //                 const firstVisibleNode = gridRef.current.api.getDisplayedRowAtIndex(0);
    //                 if (firstVisibleNode) {
    //                     firstVisibleNode.setSelected(true);
    //                     gridRef.current.api.ensureIndexVisible(firstVisibleNode.rowIndex);
    //                 }
    //             }
    //         }, 1)
    //     }
    // };

    const handleFilterSearchChange = (gstin, billno, billdate, typeofdata, filedtype, status) => {
        if (api) {
            const filterModel = {};
            if (gstin) {
                filterModel.stin = {
                    filterType: "text",
                    type: "contains",
                    filter: gstin,
                }
            }
            if (billno) {
                filterModel.inum = {
                    filterType: "text",
                    type: "contains",
                    filter: billno,
                }
            }
            if (billdate) {
                filterModel.idt = {
                    filterType: "text",
                    type: "contains",
                    filter: billdate,
                }
            }
            if (typeofdata && typeofdata !== 'All') {
                filterModel.typeofdata = {
                    filterType: "text",
                    type: "contains",
                    filter: typeofdata,
                }
            }
            if (filedtype && filedtype !== 'All') {
                if (filedtype === 'Not Filed') {
                    filterModel.srcfilstatus = {
                        filterType: 'text',
                        type: 'contains',
                        filter: filedtype,
                    };
                } else if (filedtype === 'Filed') {
                    filterModel.srcfilstatus = {
                        filterType: 'text',
                        type: 'notEqual',
                        filter: 'Not Filed',
                    };
                }
            }
            if (filterDisabled) {
                alert(2)
                return;
            }
            if (status && status !== 'All') {
                alert(1)
                filterModel.action = {
                    filterType: "text",
                    type: "contains",
                    filter: status,
                }
            }
            gridRef.current.api.setFilterModel(filterModel);
            gridRef.current.api.onFilterChanged();
            setTimeout(() => {
                const displayedRowCount = gridRef.current.api.getDisplayedRowCount();
                if (displayedRowCount > 0) {
                    const firstVisibleNode = gridRef.current.api.getDisplayedRowAtIndex(0);
                    if (firstVisibleNode) {
                        firstVisibleNode.setSelected(true);
                        gridRef.current.api.ensureIndexVisible(firstVisibleNode.rowIndex);
                    }
                }
            }, 1)
        }
    };
    const handleUpdateAllStatus = (action) => {
        if (filter.filedtype === 'Not Filed') {
            alert('Update Status Only For Filed Bills');
            return;
        }
        if (gridRef.current && gridRef.current.api) {
            const displayedRowCount = gridRef.current.api.getDisplayedRowCount();
            for (let i = 0; i < displayedRowCount; i++) {
                const rowNode = gridRef.current.api.getDisplayedRowAtIndex(i);
                console.log(rowNode);
                if (rowNode) {
                    if (rowNode.data.srcfilstatus !== 'Not Filed') {
                        if (rowNode.data.ispendactblocked && rowNode.data.ispendactblocked === 'Y') {
                            if (action !== 'P') {
                                rowNode.setDataValue('action', action);
                            }
                        }
                        if (rowNode.data.isPending) {
                            if (action !== 'N') {
                                rowNode.setDataValue('action', action);
                            }
                        }
                        else {
                            rowNode.setDataValue('action', action);
                        }
                    }
                }
            }
            gridRef.current.api.refreshCells({
                force: true,
            });
        }
    };

    const getButtonStyle = (buttonAction) => {
        const backgroundColors = {
            A: "green",
            P: "orange",
            R: "red",
            N: "blue",
        };

        return {
            backgroundColor: backgroundColors[buttonAction],
            color: "white",
            border: `1px solid ${backgroundColors[buttonAction]}`,
            padding: "2px 2px",
            margin: "0 2px",
            borderRadius: "50%",
            cursor: "pointer",
            height: '20px',
            width: '20px',
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
        };
    };
    const ActionCellRenderer = (params) => {
        if (params.node.rowPinned) {
            return;
        }
        const { value } = params;

        const getButtonStyle = (buttonAction) => {
            const backgroundColors = {
                A: "green",
                P: "orange",
                R: "red",
                N: "blue",
            };

            return {
                backgroundColor: buttonAction === value ? backgroundColors[buttonAction] : "white",
                color: buttonAction === value ? "white" : "black",
                border: `1px solid ${backgroundColors[buttonAction]}`,
                padding: "2px 2px",
                margin: "0 2px",
                borderRadius: "50%",
                cursor: "pointer",
                height: '20px',
                width: '20px',
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center"
            };
        };

        const handleUpdateStatus = (action) => {
            const gridApi = gridRef.current.api;

            // Temporarily remove the filter to allow changes to the action value
            const currentFilterModel = gridApi.getFilterModel();
            gridApi.setFilterModel(null); // Remove current filter
            gridApi.onFilterChanged();  // Trigger the filter change
            if (params.data.srcfilstatus === 'Not Filed') {
                alert('Update Status only for Filed Bills');
                return;
            }
            if (gridApi) {
                const displayedRowCount = gridRef.current.api.getDisplayedRowCount();
                for (let i = 0; i < displayedRowCount; i++) {
                    const rowNode = gridRef.current.api.getDisplayedRowAtIndex(i);
                    console.log(rowNode);
                    if (rowNode.data.num === params.data.num) {
                        alert("num")
                        if (params.data.ispendactblocked && params.data.ispendactblocked === 'Y') {
                            if (action !== 'P') {
                                params.node.setDataValue('action', action);
                            }
                        }
                        if (params.data.isPending) {
                            if (action !== 'N') {
                                params.node.setDataValue('action', action);
                            }
                        }
                        else {
                            params.node.setDataValue('action', action);
                        }
                    }
                }
                gridApi.refreshCells({
                    force: true,
                });
        
                // Reapply the filter model to keep the filtered rows J8
                gridApi.setFilterModel(currentFilterModel);
                gridApi.onFilterChanged();
            }
            // if (params.data.ispendactblocked && params.data.ispendactblocked === 'Y') {
            //     if (action !== 'P') {
            //         params.node.setDataValue('action', action);
            //     }
            // }
            // if (params.data.isPending) {
            //     if (action !== 'N') {
            //         params.node.setDataValue('action', action);
            //     }
            // }
            // else {
            //     params.node.setDataValue('action', action);
            // }
            // // setTimeout(() => {
            //     alert('update')
            //     // filterDisabled = false;
            //     // Reapply the previous filter model to keep only filtered rows
            //     gridRef.current.api.setFilterModel(currentFilterModel);
            //     gridRef.current.api.onFilterChanged();
            // // }, 500);

        };

        return (
            <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                {["A", "P", "R", "N"].map((action) => (
                    <button
                        key={action}
                        style={getButtonStyle(action)}
                        onClick={() => {
                            saveFilterModel();
                            handleUpdateStatus(action)
                        }}
                    >
                        {action}
                    </button>
                ))}
            </div>
        );
    };
    const [b2bcdnrcolumnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.5,
            valueFormatter: (params) => {
                if (params.node.rowPinned) {
                    return "(" + String(params.value) + ")";
                }
            }
        },
        { headerName: 'Data', headerClass: 'custom-header', field: 'typeofdata', editable: false, filter: "agTextColumnFilter", cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        { headerName: 'GSTIN', headerClass: 'custom-header', field: 'stin', editable: false, filter: "agTextColumnFilter", cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.3 },
        { headerName: 'Trade Name', headerClass: 'custom-header', field: 'trdnm', editable: false, filter: false, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.3 },
        { headerName: 'Bill No', headerClass: 'custom-header', field: 'inum', editable: false, filter: "agTextColumnFilter", cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.2 },
        { headerName: 'Date', headerClass: 'custom-header', field: 'idt', editable: false, filter: "agTextColumnFilter", cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.7 },
        { headerName: 'File Date', headerClass: 'custom-header', field: 'srcfilstatus', editable: false, filter: "agTextColumnFilter", cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.7 },
        {
            headerName: 'Bill Value', headerClass: 'custom-header', field: 'val', editable: false, filter: false, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.8,
            valueFormatter: (params) => {
                if (params.node.rowPinned) {
                    return "";
                }
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        // { headerName: 'Inv. Type', headerClass: 'custom-header', field: 'inv_typ', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.3 },
        {
            headerName: 'Taxable Value', headerClass: 'custom-header', field: 'txval', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'IGST', headerClass: 'custom-header', field: 'iamt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CGST', headerClass: 'custom-header', field: 'camt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'SGST', headerClass: 'custom-header', field: 'samt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        // {
        //     headerName: 'CESS', headerClass: 'custom-header', field: 'csamt', editable: false, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
        //     valueFormatter: (params) => {
        //         const value = parseFloat(params.value);
        //         if (!isNaN(value)) {
        //             return value.toFixed(2);
        //         } else {
        //             return parseFloat(0.00);
        //         }
        //     }
        // },
        // { headerName: 'Source', headerClass: 'custom-header', field: 'source', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.4 },
        {
            headerName: 'Actions',
            headerClass: 'custom-header',
            field: 'action',
            cellRenderer: ActionCellRenderer,
            filter: 'agTextColumnFilter',
            flex: 1.2,
        },
    ]);

    const [currentRowIndex, setCurrentRowIndex] = useState(0); // Start from the first row (index 0)

    useEffect(() => {
        setTimeout(() => {
            if (gridRef.current && gridRef.current.api) {
                const gridApi = gridRef.current.api;
                const totalRows = gridApi.getDisplayedRowCount();
                if (totalRows > 0) {
                    const firstRowNode = gridApi.getDisplayedRowAtIndex(0);
                    if (firstRowNode) {
                        firstRowNode.setSelected(true); // Select the first row by default
                        gridApi.ensureIndexVisible(0);
                    }
                }
            }
        }, [500])
    }, []);

    const handleCellClick = (params) => {
        const gridApi = gridRef.current.api;
        const RowNode = gridApi.getDisplayedRowAtIndex(params.rowIndex);
        setCurrentRowIndex(params.rowIndex);
        RowNode.setSelected(true);
    }

    const gotonextRow = (curRowIndex) => {
        const newRowIndex = curRowIndex + 1;
        const nextRowNode = gridRef.current.api.getDisplayedRowAtIndex(newRowIndex);
        if (nextRowNode) {
            nextRowNode.setSelected(true);
            gridRef.current.api.setFocusedCell(newRowIndex, "Action");
            return newRowIndex;
        }
        return curRowIndex;
    }

    const handleOnKeyDown = (event) => {
        const isInputField = event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement || event.target instanceof HTMLSelectElement;
        if (!gridRef.current) return;
        if (isInputField) return;

        const gridApi = gridRef.current.api;
        const totalRows = gridApi.getDisplayedRowCount();
        let newRowIndex = currentRowIndex;

        if (event.key === 'ArrowDown') {
            newRowIndex = currentRowIndex < totalRows - 1 ? currentRowIndex + 1 : currentRowIndex;
        } else if (event.key === 'ArrowUp') {
            newRowIndex = currentRowIndex > 0 ? currentRowIndex - 1 : 0;
        } else if ((event.key.toLowerCase() === 'a') || (event.key.toLowerCase() === 'p') || (event.key.toLowerCase() === 'n') || (event.key.toLowerCase() === 'r')) {
            const rowNode = gridApi.getDisplayedRowAtIndex(currentRowIndex);
            const xkey = rowNode.data?.srcfilstatus;
            if (xkey !== "Not Filed") {
                const xaction = (event.key.toLowerCase() === 'a') ? 'A' : ((event.key.toLowerCase() === 'p') ? 'P' : ((event.key.toLowerCase() === 'r') ? 'R' : 'N'))
                if (rowNode.data.ispendactblocked && rowNode.data.ispendactblocked === 'Y') {
                    if (xaction !== 'P') {
                        rowNode.setDataValue('action', xaction);
                        newRowIndex = gotonextRow(currentRowIndex)
                    }
                }
                if (rowNode.data.isPending) {
                    if (xaction !== 'N') {
                        rowNode.setDataValue('action', xaction);
                        newRowIndex = gotonextRow(currentRowIndex)
                    }
                }
                else {
                    rowNode.setDataValue('action', xaction);
                    newRowIndex = gotonextRow(currentRowIndex)
                }
            }
        }
        setCurrentRowIndex(newRowIndex);
        const rowNode = gridApi.getDisplayedRowAtIndex(newRowIndex);
        rowNode.setSelected(true);
        gridApi.ensureIndexVisible(newRowIndex);
    };

    // const selectRowsBySrlNo = (srlno, action) => {
    //     if (gridRef.current && gridRef.current.api) {
    //         gridRef.current.api.forEachNode((node) => {
    //             if (node.data.num === srlno) {
    //                 node.setDataValue('action', action);
    //             }
    //         });
    //         gridRef.current.api.refreshCells({
    //             force: true,
    //         });
    //     }
    // }

    useEffect(() => {
        const handleKeyDown = (event) => {
            handleOnKeyDown(event);
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentRowIndex]);

    const addNum = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                num: index + 1,
            };
        });
    };

    const handleFilterChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        setFilter({ ...filter, [name]: value })
    }
    const handleStatusChange = (event) => {
        const value = event.target.value;
        setStatus(value);
    }

    const handleSearchChange = (name, value) => {
        setSearch({ ...search, [name]: value })
    }

    const calculateTotals = () => {
        let total = 0, totalTaxableValue = 0, totalIGST = 0, totalCGST = 0, totalSGST = 0, totalCESS = 0;
        // const allRows = gridRef.current.api.getRenderedNodes();
        // console.log(allRows)
        gridRef.current.api.forEachNodeAfterFilter((row) => {
            total += 1;
            totalTaxableValue += isNaN(row.data.txval) ? 0 : row.data.txval;
            totalIGST += isNaN(row.data.iamt) ? 0 : row.data.iamt;
            totalCGST += isNaN(row.data.camt) ? 0 : row.data.camt;
            totalSGST += isNaN(row.data.samt) ? 0 : row.data.samt;
            totalCESS += isNaN(row.data.csamt) ? 0 : row.data.csamt;
        });
        return [
            {
                num: total,
                txval: totalTaxableValue,
                iamt: totalIGST,
                camt: totalCGST,
                samt: totalSGST,
                csamt: totalCESS,
            }
        ];
    };

    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([]);

    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            const totals = calculateTotals();
            setPinnedBottomRowData(totals);
        }
    }, [existingData, filter, search]);


    const [rainbowpanel, setRainbowPanel] = useState(false);
    const openRainbowPanel = () => {
        setRainbowPanel(true);
    }
    const closeRainbowModal = (data) => {
        if (!data) {
            return;
        }
        console.log(data);
        let IMSB2B = [], IMSCN = [], IMSDN = [];
        if (data.imsb2b.length > 0) {
            IMSB2B = data.imsb2b.map((item) => {
                if (item.action === 'P') {
                    if (item.srcfilstatus === 'Not Filed') {
                        return ({ ...item, isPending: true, srcfilstatus: 'Filed', typeofdata: 'B2B' });
                    }
                    return ({ ...item, isPending: true, typeofdata: 'B2B' });
                }
                return ({ ...item, typeofdata: 'B2B' });
            })
        }
        console.log(IMSB2B)
        if (data.imscn.length > 0) {
            IMSCN = data.imscn.map((item) => {
                if (item.action === 'P') {
                    if (item.srcfilstatus === 'Not Filed') {
                        return ({ ...item, isPending: true, srcfilstatus: 'Filed', typeofdata: 'CN', idt: item.nt_dt, inum: item.nt_num });
                    }
                    return ({ ...item, isPending: true, typeofdata: 'CN', idt: item.nt_dt, inum: item.nt_num });
                }
                return ({ ...item, typeofdata: 'CN', idt: item.nt_dt, inum: item.nt_num });
            })
        }
        if (data.imsdn.length > 0) {
            IMSDN = data.imsdn.map((item) => {
                if (item.action === 'P') {
                    if (item.srcfilstatus === 'Not Filed') {
                        return ({ ...item, isPending: true, srcfilstatus: 'Filed', typeofdata: 'DN' });
                    }
                    return ({ ...item, isPending: true, typeofdata: 'DN' });
                }
                return ({ ...item, typeofdata: 'DN' });
            })
        }
        const IMSDataArray = [IMSB2B, IMSCN, IMSDN].flatMap(data => data);
        console.log(IMSDataArray);
        setgstr2ims(IMSDataArray);
        setgstin(data.gstin);
        setRainbowPanel(false);
    }

    const getRowStyle = params => {
        if (params.node.rowPinned) {
            return { backgroundColor: "#ccc", fontWeight: "600" }
        }
    }

    const onEnterkeydown = (e, nextid, defselect = true) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            selectInput(nextid, defselect)
        }
    }

    const selectInput = (curid, defselect = true) => {
        const inputElement = document.getElementById(curid);
        if (defselect) {
            inputElement.select();
        }
        inputElement.focus();
    }

    const handleStatusApply = () => {
        // setFilter({...filter, status: status})
        // handleFilterStatusChange(status)
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                if (rainbowpanel) {
                    setRainbowPanel(false);
                    return;
                }
                navigate(-1);
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };

    }, [rainbowpanel]);

    return (
        <div className='gstr2b_ims-divmain'>
            <div className="gstr2b_ims-formcontainer">
                <div className='gstr2b_ims-header'>
                    <h6>IMS (Inward Supply)</h6>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        {/* <FaYoutube style={{ color: "#ff0000", fontSize: "28px", marginRight: "2px" }} onClick={() => window.open(formValue[1].id_value, "_blank", "noopener, noreferrer")} /> */}
                        <AiOutlineClose onClick={() => navigate(-1)} style={{ backgroundColor: "#ff0000", color: "white", fontSize: "18px" }} />
                    </div>
                </div>
                <div className='gstr2b_ims-toppanel' style={{ alignItems: "center", justifyContent: "flex-end" }}>
                    {/* <div className="input-group">
                        <label>Period:</label>
                        <input type='text' id='fp' value={filePrd} readOnly tabIndex={-1}
                            onKeyDown={(e) => onEnterkeydown(e, "gstin")}>

                        </input>
                    </div> */}
                    <div className="input-group">
                        <label>GSTIN:</label>
                        <input id='gstin' type="text" value={gstin} readOnly tabIndex={-1}
                            onKeyDown={(e) => onEnterkeydown(e, "ims", false)} />
                    </div>

                    <div className='input-group'>
                        <button id='ims' onClick={openRainbowPanel}>Imp.IMS</button>
                    </div>
                </div>
                <div className='gstr2b_ims-toppanel'>
                    <div className="search-container">
                        <FaSearch className="search-icon" />
                        <div className='search-grp'>
                            <label htmlFor="">GSTIN</label>
                            <input type='text' id='searchgstin' name='gstin' placeholder='Search GSTIN' value={search.gstin}
                                onChange={(e) => handleSearchChange(e.target.name, e.target.value)}
                                onKeyDown={(e) => onEnterkeydown(e, "searchbillno")} />
                            <AiOutlineClose onClick={() => setSearch({ ...search, gstin: '' })} style={{ backgroundColor: "none", color: "black", fontSize: "14px", position: "absolute", right: "8px" }} />
                        </div>
                        <div className='search-grp'>
                            <label htmlFor="">B.No.</label>
                            <input type='text' id='searchbillno' name='billno' placeholder='Search Bill No.' value={search.billno} onChange={(e) => handleSearchChange(e.target.name, e.target.value)}
                                onKeyDown={(e) => onEnterkeydown(e, "searchbilldate")} />
                            <AiOutlineClose onClick={() => setSearch({ ...search, billno: '' })} style={{ backgroundColor: "none", color: "black", fontSize: "14px", position: "absolute", right: "10px" }} />
                        </div>
                        <div className='search-grp'>
                            <label htmlFor="">Date</label>
                            <input type='text' id='searchbilldate' name='billdate' placeholder='Search Bill Date' value={search.billdate} onChange={(e) => handleSearchChange(e.target.name, e.target.value)}
                                onKeyDown={(e) => onEnterkeydown(e, "typeofdata", false)} />
                            <AiOutlineClose onClick={() => setSearch({ ...search, billdate: '' })} style={{ backgroundColor: "none", color: "black", fontSize: "14px", position: "absolute", right: "10px" }} />
                        </div>

                    </div>
                    <div className='top-input-grp' style={{ paddingRight: "12px" }}>
                        <div className="input-group">
                            <label htmlFor="">Filter By: </label>
                        </div>
                        <div className="input-group">
                            <select id='typeofdata'
                                name='typeofdata'
                                onChange={handleFilterChange}
                                value={filter.typeofdata}
                                onKeyDown={(e) => onEnterkeydown(e, "filedtype", false)}>
                                <option value='' disabled selected>Choose</option>
                                {dataobj.map(data => (
                                    <option key={data}
                                        value={data}>{data}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="input-group">
                            <select id='filedtype'
                                name='filedtype'
                                onChange={handleFilterChange}
                                value={filter.filedtype}
                                onKeyDown={(e) => onEnterkeydown(e, "status", false)}>
                                <option value='' disabled selected>Choose</option>
                                {filedobj.map(data => (
                                    <option key={data}
                                        value={data}>{data}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group">
                            <select id='status'
                                name='status'
                                onChange={handleFilterChange}
                                value={filter.status}
                            // onKeyDown={(e) => onEnterkeydown(e, "filtertype", false)}
                            >
                                <option value='' disabled selected>Choose</option>
                                {statusobj.map(data => (
                                    <option key={data.value}
                                        value={data.value}>{data.name}
                                    </option>
                                ))}
                            </select> &nbsp;
                            <AiOutlineCheck onClick={handleStatusApply} />
                        </div>

                        <div className='input-group' style={{ gap: "4px" }}>
                            {["A", "P", "R", "N"].map((action) => (
                                <button
                                    key={action}
                                    style={getButtonStyle(action)}
                                    onClick={() => handleUpdateAllStatus(action)}
                                >
                                    {action}
                                </button>
                            ))}
                        </div>

                    </div>
                </div>
                <div className='gstr2b_ims-container'>
                    <div className="gstr2b_ims-agegrid ">
                        <div className="aag-theme-alpine" style={{ width: "100%", height: "100%" }}>
                            <AgGridReact
                                ref={gridRef}
                                rowHeight={23}
                                rowData={existingData}
                                columnDefs={b2bcdnrcolumnDefs}
                                domLayout="normal"
                                defaultColDef={{ resizable: true, flex: 1 }}
                                pinnedBottomRowData={pinnedBottomRowData}
                                getRowStyle={getRowStyle}
                                onGridReady={onGridReady}
                                onKeyDown={handleOnKeyDown}
                                onCellClicked={handleCellClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {rainbowpanel && (
                <RainbowPanel
                    closeModal={closeRainbowModal}
                    gstrdata='IMS'
                />
            )}
        </div>
    );
};

export default AgeGridForm;
