import React, { useState, useEffect, useCallback } from 'react';
import "./ChooseCompany.css";
import CompanyList from '../GridLookup/CompanyList';
import { useDispatch, useSelector } from 'react-redux'
import { myaction } from "../../redux/Mystore"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const Company = () => {
    const navigate = useNavigate();
    const mydispatch = useDispatch();

    useEffect(() =>{
        window.document.getElementById("company").select();
        window.document.getElementById("company").focus();
    },[])
    const coyid = useSelector((xdata) => {
        return xdata.coyid
    })
    const [selectedcompany, setselectedcompany] = useState({ actcode: 0, actname: '' });
    
    useEffect(() => {
        const getCoyName = async () => {
            const data = { actcode: coyid }
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/company_readbyactcode`, data);
            if (!response.data.length > 0) {
                return;
            }
            setselectedcompany({actcode: coyid, actname: response.data[0].actname});
        }
        getCoyName();
    },[])

  
    const handleCompanyChange = (value, name) => {
        setselectedcompany({ ...selectedcompany, [name]: value });
    }
    const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
    const closeCompanyModal = () => {
        setIsCompanyModalOpen(false);
    };
    const handleCompanyRowDoubleClick = (data) => {
        const rowData = data;
        setIsCompanyModalOpen(false);
        setselectedcompany({ actcode: rowData.actcode, actname: rowData.actname });
        window.document.getElementById("save").focus();
    };
    const handleSave = () => {
        if (selectedcompany.actname === '') {
            alert("Company Name Should Be Given");
            window.document.getElementById("company").focus();
            return;
        }
        mydispatch(myaction.mycoyid(selectedcompany.actcode));
        navigate(-1);
    }
    return (
        <div className='company-divmain'>
            <div className='company-container'>
                <div className='company-inputgrp'>
                    <label htmlFor="company">Company:</label>
                    <input type="text" id='company' name='company' placeholder='Company Name' value={selectedcompany.actname}
                        onChange={((e) => handleCompanyChange(e.target.value, e.target.name))}
                        onDoubleClick={() => setIsCompanyModalOpen(true)}
                        onKeyDown={(event) => {
                            if (event.key === 'F4') {
                                setIsCompanyModalOpen(true);
                            }
                            if (event.key === 'Enter') {
                                window.document.getElementById("save").focus();
                            }
                        }}
                    />
                    {isCompanyModalOpen && (
                        <CompanyList
                            closeModal={closeCompanyModal}
                            onRowDoubleClick={handleCompanyRowDoubleClick}
                            selecteditems={selectedcompany}
                        />
                    )}
                </div>
                <div className='company-btngrp'>
                    <button id='save' onClick={handleSave}>Select</button>
                    <button onClick={() => navigate(-1)}>Cancel</button>
                </div>
            </div>
        </div>
    )
}
export default Company;