import React, { useState, useRef, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./ProductMasterList.css";
import axios from "axios";
import { FaSearch} from "react-icons/fa";
import { AiOutlineClose, AiOutlinePlus, AiOutlineCheck, AiOutlineEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { myaction } from '../../redux/Mystore';

const ProductMasterList = ({ closeModal, onRowDoubleClick, selecteditems }) => {
  const [initialdata, setinitialdata] = useState(selecteditems);
  const [curdata, setcurdata] = useState([]);

  const gridRef = useRef(null);
  const searchNameRef = useRef(null);
  const mydispatch = useDispatch()
  const navigate = useNavigate();
  const fetchdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RAINBOW_URL}/company_readall`
      );
      setSelectedRow(response.data[0]);
      setcurdata(response.data);
      if (initialdata.actcode === 0) {
        setinitialdata(response.data[0]);
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  useEffect(() => {
    searchNameRef.current.focus();
  }, []);

  const columnDefs = [
    {
      headerName: "Company Name",
      field: "actname",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 300,
      cellStyle: {
        overflow: "hidden",
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    {
      headerName: "Place",
      field: "place",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 120,
      cellStyle: {
        overflow: "hidden",
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    {
      headerName: "GSTIN",
      field: "gstin",
      sortable: true,
      filter: "agTextColumnFilter",
      width: 160,
      cellStyle: {
        overflow: "hidden",
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    { headerName: "Company Code", field: "actcode", hide: true },
  ];
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  useEffect(() => {
    if (initialdata && gridRef.current && gridRef.current.api) {
      const index = curdata.findIndex(
        (item) => item.actcode === initialdata.actcode
      ); // Adjust based on your data structure
      if (index >= 0) {
        gridRef.current.api.forEachNode((node) => {
          if (node.data.actcode === initialdata.actcode) {
            node.setSelected(true); // Select the item
            gridRef.current.api.ensureIndexVisible(index); // Scroll to the selected row
            setCurrentRowIndex(index);
          }
        });
      } else {
        const firstNode = gridRef.current.api.getDisplayedRowAtIndex(0); // Get the first row node
        if (firstNode) {
          firstNode.setSelected(true); // Select the first row
          gridRef.current.api.ensureIndexVisible(0); // Scroll to the first row
          setCurrentRowIndex(0);
        }
      }
    }
  }, [initialdata, curdata]);

  const [api, setApi] = useState(null);

  const onGridReady = (params) => {
    setApi(params.api); // Set API to state
  };

  const handleFilterTextChange = (filterValue) => {
    if (api) {
      const filterModel = {
        actname: {
          filterType: "text",
          type: "contains",
          filter: filterValue,
        },
      };
      gridRef.current.api.setFilterModel(filterModel);
      gridRef.current.api.onFilterChanged();
      setTimeout(() => {
        const displayedRowCount = gridRef.current.api.getDisplayedRowCount();
        if (displayedRowCount > 0) {
          const firstVisibleNode =
            gridRef.current.api.getDisplayedRowAtIndex(0);
          if (firstVisibleNode) {
            firstVisibleNode.setSelected(true);
            gridRef.current.api.ensureIndexVisible(firstVisibleNode.rowIndex);
            setCurrentRowIndex(firstVisibleNode.rowIndex)
          }
        }
      }, 1);
    }
  };

  const getRowClass = (params) => {
    return params.node.isSelected() ? "aggrid-selecteditem" : "";
  };

  const [SelectedRow, setSelectedRow] = useState(null);

  const onSelectionChanged = useCallback((event) => {
    const selectedNodes = event.api.getSelectedNodes();
    if (selectedNodes.length > 0) {
      setSelectedRow(selectedNodes[0].data);
    } else {
      setSelectedRow(null);
    }
  }, []);

  const onRowSelected = useCallback((event) => {
    if (event.node.selected) {
      setSelectedRow(event.data);
    } else {
      setSelectedRow(null);
    }
  }, []);

  const onClickSelect = () => {
    onRowDoubleClick(SelectedRow);
  };

  const onRowDoubleClicked = (event) => {
    setSelectedRow(event.data);
    onRowDoubleClick(event.data);
  };

  const onCellFocused = useCallback((event) => {
    const { rowIndex } = event;
    if (rowIndex !== undefined) {
      const rowNode = event.api.getDisplayedRowAtIndex(rowIndex);
      if (rowNode) {
        rowNode.setSelected(true);
      }
    }
  }, []);

  const onCellKeyDown = useCallback((event) => {
    const { node, event: nativeEvent } = event;
    const key = nativeEvent.key || nativeEvent.code;
    switch (key) {
      case "Enter":
        if (node) {
          setSelectedRow(node.data); // Update state with selected row data
          onRowDoubleClick(node.data);
        }
        break;
      default:
        break;
    }
  }, []);

  const handledivKeyDown = (event) => {
    if (event.key === "Enter" && gridRef.current) {
      const selectedNode = gridRef.current.api.getSelectedNodes()[0];
      if (selectedNode) {
        event.stopPropagation();
        setSelectedRow(selectedNode.data); // Update state with selected row data
        onRowDoubleClick(selectedNode.data);
      }
    }
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
      event.stopPropagation();
      return;
    }
    if (event.key === "Enter") {
      if (SelectedRow) {
        onRowDoubleClick(SelectedRow);
      }
      return;
    }
    const totalRows = gridRef.current.api.getDisplayedRowCount();
    if (event.key === "ArrowDown") {
      let newRowIndex =
        currentRowIndex < totalRows - 1 ? currentRowIndex + 1 : currentRowIndex;
      setCurrentRowIndex(newRowIndex);
      gridRef.current.api.ensureIndexVisible(newRowIndex);
      gridRef.current.api.getDisplayedRowAtIndex(newRowIndex).setSelected(true);
    } else if (event.key === "ArrowUp") {
      let newRowIndex = currentRowIndex > 0 ? currentRowIndex - 1 : 0;
      setCurrentRowIndex(newRowIndex);
      gridRef.current.api.ensureIndexVisible(newRowIndex);
      gridRef.current.api.getDisplayedRowAtIndex(newRowIndex).setSelected(true);
    }
  };

  const handleCompanyNew = () =>{
    mydispatch(myaction.setcompanymode(["New", 0]));
    navigate("/installcompany")
  }

  const handleCompanyEdit = () =>{
    mydispatch(myaction.setcompanymode(["Edit", SelectedRow.actcode]));
        navigate("/installcompany")
  }

  return (
    <div className="frmSelect-modal">
      <div className="frmSelect-modal-content">
        <div className="frmSelect-mainpage">
          <div div className="frmSelect-main">
            <div
              style={{
                margin: 1,
                height: "98%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className="ag-theme-alpine custom-header"
                style={{ margin: 1, height: "98%", width: "100%" }}
                tabIndex={0}
                onKeyDown={handledivKeyDown} // Listen for key events
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={curdata}
                  columnDefs={columnDefs}
                  rowHeight={20}
                  onRowSelected={onRowSelected}
                  onRowDoubleClicked={onRowDoubleClicked}
                  onCellFocused={onCellFocused}
                  onCellKeyDown={onCellKeyDown}
                  onSelectionChanged={onSelectionChanged}
                  rowSelection="single"
                  enableRangeSelection={false}
                  // getRowClass={getRowClass}
                  onGridReady={onGridReady}
                  enableFilter={true}
                />
              </div>
            </div>

            <div className="frmSelect-bottom">
              <div className="search-container">
                <input
                  type="text"
                  name="customername"
                  ref={searchNameRef}
                  autoComplete="off"
                  className="search-input"
                  onChange={(e) => handleFilterTextChange(e.target.value)}
                  onKeyDown={handleOnKeyDown}
                />
                <FaSearch className="search-icon" />
              </div>
              <div className="btngroup">
                <div className="editgrp">
                  <AiOutlinePlus onClick={handleCompanyNew} style={{ backgroundColor: "lightskyblue", color: "blue", fontSize: "25px", borderRadius:"50%", padding:"3px", border: "1px solid blue"}} />
                  <AiOutlineEdit onClick={handleCompanyEdit} style={{ backgroundColor: "greenyellow", color: "blue", fontSize: "25px",borderRadius:"50%", padding:"3px", border: "1px solid blue" }} />
                </div>
                <div className="selectgrp">               
                <AiOutlineCheck onClick={closeModal} style={{ backgroundColor: "green", color: "white", fontSize: "25px", borderRadius:"50%", padding:"3px", border: "1px solid blue" }} />
                <AiOutlineClose onClick={closeModal} style={{ backgroundColor: "#ff0000", color: "white", fontSize: "25px", borderRadius:"50%", padding:"3px", border: "1px solid blue" }} />
                </div>

                {/* <button className="btn" onClick={closeModal}>
                  Close
                </button> */}
                {/* <button className="btn" onClick={onClickSelect}>
                  Select
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductMasterList;
