import "./company.css"
import React, { useState, useEffect, useRef } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import axios from 'axios';
import CheckboxCircle from "../CheckboxCircle/CheckboxCircle";
import * as u from "../utils/Common";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';

const Company = () => {


    let url = '';

    const curmode = useSelector(function (data) {
        return data.companymode;
    })

    const navigate = useNavigate();
    const [mobNumbers, setMobNumbers] = useState([{ mobNo: '', whatsapp: false }, { mobNo: '', whatsapp: false }, { mobNo: '', whatsapp: false }, { mobNo: '', whatsapp: false }, { mobNo: '', whatsapp: false }, { mobNo: '', whatsapp: false }])

    const stateRef = useRef('')
    const [statedata, setstatedata] = useState([]);
    const [selectedState, setselectedState] = useState('');

    const actnameRef = useRef('');
    const address1Ref = useRef('');
    const placeRef = useRef('');
    const pincodeRef = useRef('');

    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [isChecked4, setIsChecked4] = useState(false);
    const [isChecked5, setIsChecked5] = useState(false);
    const [isChecked6, setIsChecked6] = useState(false);

    const handleCheckboxChange1 = () => {
        setIsChecked1(!isChecked1);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[0].whatsapp = !isChecked1;
        setMobNumbers(newMobileNumbers);
    };
    const handleCheckboxChange2 = () => {
        setIsChecked2(!isChecked2);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[1].whatsapp = !isChecked2;
        setMobNumbers(newMobileNumbers);
    };
    const handleCheckboxChange3 = () => {
        setIsChecked3(!isChecked3);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[2].whatsapp = !isChecked3;
        setMobNumbers(newMobileNumbers);
    };
    const handleCheckboxChange4 = () => {
        setIsChecked4(!isChecked4);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[3].whatsapp = !isChecked4;
        setMobNumbers(newMobileNumbers);
    };
    const handleCheckboxChange5 = () => {
        setIsChecked5(!isChecked5);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[4].whatsapp = !isChecked5;
        setMobNumbers(newMobileNumbers);
    };
    const handleCheckboxChange6 = () => {
        setIsChecked6(!isChecked6);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[5].whatsapp = !isChecked6;
        setMobNumbers(newMobileNumbers);
    };

    useEffect(() => {
        setTimeout(() => {
            actnameRef.current.focus();
        }, [100])
    }, []);

    useEffect(() => {
        const fetchdata = async (e) => {
            if (curmode[0] === "Edit") {
                const data = { actcode: curmode[1] };
                const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/company_readbyactcode`, data)
                if (response.data.length > 0) {
                    formdata.actname = response.data[0].actname;
                    formdata.address1 = response.data[0].add1
                    formdata.address2 = response.data[0].add2;
                    formdata.address3 = response.data[0].add3;
                    formdata.address4 = response.data[0].add4;
                    formdata.place = response.data[0].place;
                    formdata.pincode = response.data[0].pincode;
                    formdata.adhaar = response.data[0].adhaar;
                    formdata.pan = response.data[0].pan;
                    formdata.gstin = response.data[0].gstin;
                    formdata.gstincanceldate = response.data[0].gstincanceldate;
                    formdata.statename = response.data[0].statename;
                    setselectedState(response.data[0].statename);
                    formdata.email = response.data[0].email;
                    formdata.website = response.data[0].website;
                    formdata.iecode = response.data[0].iecode;
                    formdata.explic1 = response.data[0].explic1;
                    formdata.explic2 = response.data[0].explic2;
                    formdata.contactperson = response.data[0].contactperson;
                    setformdata(formdata);
                    const newMobileNumbers = [...mobNumbers];
                    newMobileNumbers[0].whatsapp = response.data[0].mobile1whatsapp === 1 ? true : false;
                    newMobileNumbers[1].whatsapp = response.data[0].mobile2whatsapp === 1 ? true : false;
                    newMobileNumbers[2].whatsapp = response.data[0].mobile3whatsapp === 1 ? true : false;
                    newMobileNumbers[0].mobNo = response.data[0].mobile1;
                    newMobileNumbers[1].mobNo = response.data[0].mobile2;
                    newMobileNumbers[2].mobNo = response.data[0].mobile3;
                    newMobileNumbers[3].whatsapp = response.data[0].cpmobile1whatsapp === 1 ? true : false;
                    newMobileNumbers[4].whatsapp = response.data[0].cpmobile2whatsapp === 1 ? true : false;
                    newMobileNumbers[5].whatsapp = response.data[0].copmobile3whatsapp === 1 ? true : false;
                    newMobileNumbers[3].mobNo = response.data[0].cpmobile1;
                    newMobileNumbers[4].mobNo = response.data[0].cpmobile2;
                    newMobileNumbers[5].mobNo = response.data[0].cpmobile3;
                    setIsChecked1(response.data[0].mobile1whatsapp);
                    setIsChecked2(response.data[0].mobile2whatsapp);
                    setIsChecked3(response.data[0].mobile3whatsapp);
                    setIsChecked4(response.data[0].cpmobile1whatsapp);
                    setIsChecked5(response.data[0].cpmobile2whatsapp);
                    setIsChecked6(response.data[0].cpmobile3whatsapp);
                    setMobNumbers(newMobileNumbers);
                }
            }
        };

        const fetchstatedata = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/readstatemaster`)
                setstatedata(response.data);
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchstatedata()
        fetchdata()
    }, []);

    const handleMobChange = (index, event) => {
        const { value } = event.target;
        if (!(/^\d*$/.test(value) && value.length <= 10)) {
            return;
        }
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[index].mobNo = event.target.value;
        setMobNumbers(newMobileNumbers);
    };

    const handleStateChange = (event) => {
        setselectedState(event.target.value)
    }

    const handleSave = async (e) => {
        let newValue = u.myTrim(formdata.actname);
        if (newValue === "") {
            alert("Name Should be Given");
            actnameRef.current.focus()
            return;
        }

        newValue = u.myTrim(formdata.address1);
        if (newValue === "") {
            alert("Address Should be Given");
            address1Ref.current.focus()
            return;
        }

        newValue = u.myTrim(formdata.place);
        if (newValue === "") {
            alert("Place Should be Given");
            placeRef.current.focus()
            return;
        }

        newValue = u.myTrim(formdata.pincode);
        if (newValue === "") {
            alert("Pincode should be Given");
            pincodeRef.current.focus()
            return;
        }
        if (!u.validatePinCode(newValue)) {
            alert("Invalid Pincode..");
            pincodeRef.current.focus()
            return;
        }
        newValue = formdata.gstincanceldate;
        if (!u.validateDate(newValue) && newValue.length > 0) {
            alert("Invalid Cancel Date");
            window.document.getElementById("gstincanceldate").focus();
            return;
        }
        // newValue = curSelectedState[1]
        newValue = selectedState;
        if (newValue === "") {
            alert("State Should be Given");
            stateRef.current.focus()
            return;
        }

        try {
            const data = {
                actcode: (curmode[0] === "New" ? 0 : curmode[1]),
                actname: u.myTrim(formdata.actname),
                add1: u.myTrim(formdata.address1),
                add2: u.myTrim(formdata.address2),
                add3: u.myTrim(formdata.address3),
                add4: u.myTrim(formdata.address4),
                place: u.myTrim(formdata.place),
                pincode: u.myTrim(formdata.pincode),
                gstin: u.myTrim(formdata.gstin),
                gstincanceldate: u.myTrim(formdata.gstincanceldate),
                statename: selectedState,
                adhaar: u.myTrim(formdata.adhaar),
                pan: u.myTrim(formdata.pan),
                mobile1: u.myTrim(mobNumbers[0].mobNo),
                mobile2: u.myTrim(mobNumbers[1].mobNo),
                mobile3: u.myTrim(mobNumbers[2].mobNo),
                mobile1whatsapp: mobNumbers[0].whatsapp ? 1 : 0,
                mobile2whatsapp: mobNumbers[1].whatsapp ? 1 : 0,
                mobile3whatsapp: mobNumbers[2].whatsapp ? 1 : 0,
                iecode: u.myTrim(formdata.iecode),
                explic1: u.myTrim(formdata.explic1),
                explic2: u.myTrim(formdata.explic2),
                email: u.myTrim(formdata.email),
                website: u.myTrim(formdata.website),
                contactperson: u.myTrim(formdata.contactperson),
                cpmobile1: u.myTrim(mobNumbers[3].mobNo),
                cpmobile2: u.myTrim(mobNumbers[4].mobNo),
                cpmobile3: u.myTrim(mobNumbers[5].mobNo),
                cpmobile1whatsapp: mobNumbers[3].whatsapp ? 1 : 0,
                cpmobile2whatsapp: mobNumbers[4].whatsapp ? 1 : 0,
                cpmobile3whatsapp: mobNumbers[5].whatsapp ? 1 : 0
            };
            url = `${process.env.REACT_APP_RAINBOW_URL}/company_create`
            if (curmode[0] === "Edit") {
                url = `${process.env.REACT_APP_RAINBOW_URL}/company_update`
            }
            await axios.post(url, data);
            if (curmode[0] === "Edit") {
                navigate(-1)
                return;
            }

        } catch (error) {
            console.error('Error submitting form:', error);
        }
        resetform();
        actnameRef.current.focus();
    }

    function resetform() {
        formdata.actname = '';
        formdata.address1 = '';
        formdata.address2 = '';
        formdata.address3 = '';
        formdata.address4 = '';
        formdata.place = '';
        formdata.pincode = '';
        formdata.adhaar = '';
        formdata.gstin = '';
        formdata.gstincanceldate = '';
        formdata.pan = '';
        formdata.email = '';
        formdata.website = '';
        formdata.iecode = '';
        formdata.explic1 = '';
        formdata.explic2 = '';
        formdata.contactperson = '';
        setformdata(formdata);
        const newMobileNumbers = [...mobNumbers];
        newMobileNumbers[0].whatsapp = false;
        newMobileNumbers[1].whatsapp = false;
        newMobileNumbers[2].whatsapp = false;
        newMobileNumbers[0].mobNo = '';
        newMobileNumbers[1].mobNo = '';
        newMobileNumbers[2].mobNo = '';
        setMobNumbers(newMobileNumbers);
    }

    const [formdata, setformdata] = useState({
        actname: '',
        address1: '',

        address2: '',
        address3: '',
        address4: '',
        place: '',
        pincode: '',
        adhaar: '',
        pan: '',
        gstin: '',
        statecode: 0,
        statename: '',
        gstincanceldate: '',
        email: '',
        website: '',
        iecode: '',
        explic1: '',
        explic2: '',
        contactperson: '',
    });

    const handleformchange = (event) => {
        let { name, value } = event.target;
        if (name === "pincode") {
            if (!(/^\d*$/.test(value) && value.length <= 6)) {
                return;
            }
        } else if (name === "gstin") {
            if (!(/^[A-Z0-9a-z]*$/.test(value) && value.length <= 15)) {
                return;
            }
            value = value.toUpperCase();
        } else if (name === "pan") {
            if (!(/^[A-Z0-9a-z]*$/.test(value) && value.length <= 10)) {
                return;
            }
            value = value.toUpperCase();
        } else if (name === "iecode") {
            if (!(/^[A-Z0-9a-z]*$/.test(value) && value.length <= 10)) {
                return;
            }
            value = value.toUpperCase();
        } else if (name === "explic1" || name === "explic2") {
            if (!(value.length <= 25)) {
                return;
            }
            value = value.toUpperCase();
        } else if (name === "email" || name === "website") {
            value = value.toLowerCase();
        } else {
            value = u.toProperCase(value);
        }
        setformdata({ ...formdata, [name]: value, })
        if (name === "gstin") {
            const xdata = u.myTrim(value).substring(0, 2);
            const xdata2 = statedata.filter((state) => state.gststatecode === xdata)
            if (xdata2.length > 0) {
                setselectedState(xdata2[0].statename)
            } else {
                setselectedState("")
            }
        }
    };

    const defaultEnterRef = useRef(null)
    const defaultEscapeRef = useRef(null)

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            // if (event.key === 'Enter') {
            //     defaultEnterRef.current.click();
            // }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const frmClose = () => {
        navigate(-1);
    }

    const onEnterkeydown = (e, nextid, defselect = true) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            selectInput(nextid, defselect)
        }
    }

    const selectInput = (curid, defselect = true) => {
        const inputElement = document.getElementById(curid);
        if (defselect) {
            inputElement.select();

        }

        inputElement.focus();
    }

    return (
        <>
            <div className="frmcompany-divmain" >
                <div className="frmcompany-main">
                    <div className="frmcompany-container-page">
                        <div className="frmcompany-header">
                            <h3>Company Information</h3>
                        </div>
                        <div className="frmcompany-container">
                            <div className="frmcompany-leftpanel">
                                <div className="frmcompany-group">
                                    <label for="actname" className="frmcompany-label required">Account Name</label>
                                    <input type="text" id="name" name="actname" value={formdata.actname}
                                     onChange={handleformchange} className="frmcompany-input" required autofocus 
                                     maxlength="75" ref={actnameRef} onKeyDown={(e) => onEnterkeydown(e,"address1")}/>
                                </div>
                                <div className="frmcompany-group">
                                    <label for="address1" className="frmcompany-label required">Address</label>
                                    <input type="text" id="address1" name="address1" value={formdata.address1} 
                                    onChange={handleformchange} className="frmcompany-input" required ref={address1Ref}
                                    onKeyDown={(e) => onEnterkeydown(e, "address2")} />
                                </div>
                                <div className="frmcompany-group">
                                    <label for="address2" className="frmcompany-label "></label>
                                    <input type="text" id="address2" name="address2" value={formdata.address2} 
                                    onChange={handleformchange} className="frmcompany-input"
                                    onKeyDown={(e) => onEnterkeydown(e, "address3")} />
                                </div>
                                <div className="frmcompany-group">
                                    <label for="address3" className="frmcompany-label "></label>
                                    <input type="text" id="address3" name="address3" value={formdata.address3} 
                                    onChange={handleformchange} className="frmcompany-input" 
                                    onKeyDown={(e) => onEnterkeydown(e, "frmcompany-place")}/>
                                </div>
                                <div className="frmcompany-group">
                                    <label for="address4" className="frmcompany-label "></label>
                                    <input
                                        type="text"
                                        id="address4"
                                        name="address4"
                                        value={formdata.address4}
                                        onChange={handleformchange}
                                        className="frmcompany-input"
                                        tabIndex={-1}
                                        onKeyDown={(e) => onEnterkeydown(e, "frmcompany-place")}
                                    />
                                </div>

                                <div className="frmcompany-group">
                                    <label for="place" class="frmcompany-label required">Place</label>
                                    <div className="frmcompany-group-sub">
                                        <input type="text" id="frmcompany-place" name="place" value={formdata.place}
                                         onChange={handleformchange} ref={placeRef} 
                                         onKeyDown={(e) => onEnterkeydown(e, "frmcompany-pincode")}/>
                                        <label for="pincode" id="frmcompany-label-pincode" className="required">Pin Code</label>
                                        <input type="text" id="frmcompany-pincode" name="pincode" ref={pincodeRef} 
                                        onChange={handleformchange} maxLength="6" value={formdata.pincode} 
                                        onKeyDown={(e) => onEnterkeydown(e, "frmcompany-gstin")}/>
                                    </div>
                                </div>

                                <div className="frmcompany-group">
                                    <label for="gstin" class="frmcompany-label">GSTIN</label>
                                    <div className="frmcompany-group-sub">
                                        <input type="text" id="frmcompany-gstin" name="gstin" onChange={handleformchange}
                                         maxLength="15" value={formdata.gstin} 
                                         onKeyDown={(e) => onEnterkeydown(e, "gstincanceldate")}/>
                                        <label for="gstincanceldate" id="frmcompany-label-pincode">GSTIN Cancel dt.</label>
                                        <InputMask
                                            mask="99/99/99"
                                            id="gstincanceldate" name="gstincanceldate" onChange={handleformchange} value={formdata.gstincanceldate}
                                            placeholder="DD/MM/YY"
                                            maskChar=""
                                            onKeyDown={(e) => onEnterkeydown(e, "frmcompany-state", false)}
                                        />

                                    </div>
                                </div>

                                <div className="frmcompany-group">
                                    <label for="state" className="frmcompany-label">State</label>
                                    <div className="frmcompany-group-sub">
                                        <select id="frmcompany-state" name="selectedstate" required 
                                        onChange={handleStateChange} value={selectedState} ref={stateRef}
                                        onKeyDown={(e) => onEnterkeydown(e, "frmcompany-aadhar")}>
                                            <option value="" disabled selected>Select State...</option>
                                            {statedata.map((data, index) => (
                                                <option key={index + 1} value={data.statename}>{data.statename}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="frmcompany-group">
                                    <label for="aadhaar" className="frmcompany-label">Adhaar</label>
                                    <div className="frmcompany-group-sub">
                                        <input type="text" id="frmcompany-aadhar" name="adhaar" onChange={handleformchange} 
                                        maxLength="12" value={formdata.adhaar} 
                                        onKeyDown={(e) => onEnterkeydown(e, "frmcompany-pan")}/>
                                        <label for="pan" id="frmcompany-label-pan">PAN</label>
                                        <input type="text" name="pan" id="frmcompany-pan" onChange={handleformchange} maxLength="10" 
                                        value={formdata.pan}   onKeyDown={(e) => onEnterkeydown(e, "frmcompany-mobile1")}/>
                                    </div>
                                </div>

                                <div className="frmcompany-group">
                                    <label for="mobile number-1" className="frmcompany-label">Mobile</label>
                                    <div className="frmcompany-group-sub">
                                        <div className="frmcompany-group-sub2">
                                            <input type="text" id="frmcompany-mobile1" name="mobile number-1"
                                                value={mobNumbers[0].mobNo}
                                                onChange={(event) => handleMobChange(0, event)}
                                                onKeyDown={(e) => onEnterkeydown(e, "frmcompany-mobile2")}
                                            />
                                            <FaWhatsapp className="fa-whatsapp-icon" />
                                            <CheckboxCircle
                                                checked={isChecked1}
                                                onChange={handleCheckboxChange1}
                                                tabindex={-1}
                                                className="cricleicon"
                                            />
                                        </div>

                                        <div className="frmcompany-group-sub2">
                                            <input type="text" id="frmcompany-mobile2" name="mobile number-1"
                                                value={mobNumbers[1].mobNo}
                                                onChange={(event) => handleMobChange(1, event)}
                                                onKeyDown={(e) => onEnterkeydown(e, "iecode")}
                                            />
                                            <FaWhatsapp className="fa-whatsapp-icon" />
                                            <CheckboxCircle
                                                checked={isChecked2}
                                                onChange={handleCheckboxChange2}
                                                tabindex={-1}
                                                className="cricleicon"
                                            />
                                        </div>

                                        <div className="frmcompany-group-sub2">
                                            <input type="text" id="frmcompany-mobile3" name="mobile number-1"
                                                tabIndex={-1}
                                                value={mobNumbers[2].mobNo}
                                                onChange={(event) => handleMobChange(2, event)}
                                                onKeyDown={(e) => onEnterkeydown(e, "iecode")}
                                            />
                                            <FaWhatsapp className="fa-whatsapp-icon" />
                                            <CheckboxCircle
                                                checked={isChecked3}
                                                onChange={handleCheckboxChange3}
                                                tabindex={-1}
                                                className="cricleicon"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="frmcompany-group">
                                    <label for="iecode" className="frmcompany-label">IE Code</label>
                                    <div className="frmcompany-group-sub">
                                        <input type="text" id="iecode" name="iecode" className="frmcompany-input" 
                                        onChange={handleformchange} value={formdata.iecode} 
                                        onKeyDown={(e) => onEnterkeydown(e, "frmcompany-explic1")}/>
                                    </div>
                                </div>


                                <div className="frmcompany-group">
                                    <label for="explicence" className="frmcompany-label">Exp.Licence</label>
                                    <div className="frmcompany-group-sub">
                                        <div className="accledger-group-grid">
                                            <input type="text" id="frmcompany-explic1" name="explic1" 
                                            onChange={handleformchange} value={formdata.explic1} 
                                            onKeyDown={(e) => onEnterkeydown(e, "frmcompany-explic2")}/>
                                            <input type="text" id="frmcompany-explic2" name="explic2" 
                                            onChange={handleformchange} value={formdata.explic2} 
                                            onKeyDown={(e) => onEnterkeydown(e, "email")}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="frmcompany-group">
                                    <label for="email" className="frmcompany-label">E-mail</label>
                                    <div className="frmcompany-group-sub">
                                        <input type="text" id="email" name="email" className="frmcompany-input" 
                                        onChange={handleformchange} value={formdata.email} 
                                        onKeyDown={(e) => onEnterkeydown(e, "website")}/>
                                    </div>
                                </div>

                                <div className="frmcompany-group">
                                    <label for="website" className="frmcompany-label">webSite</label>
                                    <div className="frmcompany-group-sub">
                                        <input type="text" id="website" name="website" className="frmcompany-input" 
                                        onChange={handleformchange} value={formdata.website} 
                                        onKeyDown={(e) => onEnterkeydown(e, "contactperson")}/>
                                    </div>
                                </div>

                                <div className="frmcompany-group">
                                    <label for="contactperson" className="frmcompany-label">Contact Person</label>
                                    <div className="frmcompany-group-sub">
                                        <input type="text" id='contactperson' name="contactperson" className="frmcompany-input"
                                         onChange={handleformchange} value={formdata.contactperson} 
                                         onKeyDown={(e) => onEnterkeydown(e, "frmcompany-cpmobile1")}/>
                                    </div>
                                </div>

                                <div className="frmcompany-group">
                                    <label for="mobile number-1" className="frmcompany-label">Mobile</label>
                                    <div className="frmcompany-group-sub">
                                        <div className="frmcompany-group-sub2">
                                            <input type="text" id="frmcompany-cpmobile1" name="mobile number-1"
                                                value={mobNumbers[3].mobNo}
                                                onChange={(event) => handleMobChange(3, event)}
                                                onKeyDown={(e) => onEnterkeydown(e, "frmcompany-cpmobile2")}
                                            />
                                            <FaWhatsapp className="fa-whatsapp-icon" />
                                            <CheckboxCircle
                                                checked={isChecked4}
                                                onChange={handleCheckboxChange4}
                                                tabindex={-1}
                                                className="cricleicon"
                                            />
                                        </div>

                                        <div className="frmcompany-group-sub2">
                                            <input type="text" id="frmcompany-cpmobile2" name="mobile number-1"
                                                value={mobNumbers[4].mobNo}
                                                onChange={(event) => handleMobChange(4, event)}
                                                onKeyDown={(e) => onEnterkeydown(e, "frmcompany-cpmobile3")}
                                            />
                                            <FaWhatsapp className="fa-whatsapp-icon" />
                                            <CheckboxCircle
                                                checked={isChecked5}
                                                onChange={handleCheckboxChange5}
                                                tabindex={-1}
                                                className="cricleicon"
                                            />
                                        </div>

                                        <div className="frmcompany-group-sub2">
                                            <input type="text" id="frmcompany-cpmobile3" name="mobile number-1"
                                                value={mobNumbers[5].mobNo}
                                                onChange={(event) => handleMobChange(5, event)}
                                                onKeyDown={(e) => onEnterkeydown(e, "save", false)}
                                            />
                                            <FaWhatsapp className="fa-whatsapp-icon" />
                                            <CheckboxCircle
                                                checked={isChecked6}
                                                onChange={handleCheckboxChange6}
                                                tabindex={-1}
                                                className="cricleicon"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="frmcompany-btngroup">
                                    <button tabIndex={-1} className='frmcompany-btn' ref={defaultEscapeRef} onClick={frmClose}> Cancel</button>
                                    <button id="save" className='frmcompany-btn' ref={defaultEnterRef} onClick={() => handleSave()} >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Company;
