import { Routes,Route } from "react-router-dom"
import React from "react";
import MyLoginPage from "../pages/MyLoginPage";
import Signin from "../components/Users/Signin";
import Login from "../components/Users/Login";
import Emptypage from "../pages/Emptypage";
import Flashnews from "../components/flashNews/Flashnews";
import License from "../components/License/License";
import Company from "../components/Company/Company";
import ChooseCompany from "../components/Company/ChooseCompany";
import Contactus from "../components/ContactUs/ContactUs";
import AnnualReturn from "../components/AnnualReturn/AnnualReturn";
import GSTR1View from "../components/GSTR/GSTR1View";
import GSTR2B from "../components/GSTR/GSTR2B";
import UploadPdf from "../components/Whatsapp/Uploadpdf";
import SMSSettings from "../components/Whatsapp/SMSSettings";
import PrintSettings from "../components/Settings/PrintSettings";
import UsersList from "../components/UsersList/UsersList";
import ChangePassword from "../components/Users/ChangePassword";
import DeliveryChalanLookup from "../components/DeliveryChalan/DeliveryChalanLookup";
import DeliveryChalan from "../components/DeliveryChalan/DeliveryChalan";

import ProductMaster from "../components/productmaster/ProductMaster";
import ProductGroup from "../components/productgroup/ProductGroup";
import Transport from "../components/transport/Transport";
import Stockplace from "../components/stockplace/Stockplace";
import PriceList from "../components/pricelist/PriceList";

import PdtGroupLookup from "../components/productgroup/PdtGroupLookup";
import PdtMasterLookup from "../components/productmaster/PdtMasterLookup";
import TransportLookup from "../components/transport/TransportLookup";
import Stockplacelookup from "../components/stockplace/Stockplacelookup";
import PriceListLookup from "../components/pricelist/PriceListLookup";
import CompanyLookup from "../components/Company/CompanyLookup";

import AccLedger from "../components/accledger/AccLedger";
import AccLedgerLookup from "../components/accledger/AccLedgerLookup";
import RainbowPanel from "../components/GSTR/RainbowPanel";
import GSTR2IMS from "../components/GSTR/GSTR2_IMS";


const Routers = () => {
  return (
    <>
    
    <Routes>
        <Route path="/" element={<MyLoginPage/>}/>
        <Route path="/login" element={ <Login/>}/>
        <Route path="/signin" element={ <Signin/>}/>
        <Route path="/forgotpassword" element={ <Signin formmode={'FP'}/>}/>
        <Route path="/changepassword" element={ <ChangePassword/>}/>
        <Route path="/emptypage" element={ <Emptypage/>}/>

        <Route path="/flashnews" element={  <Flashnews/>}/>
        <Route path="/license" element={ <License/>}/>
        <Route path="/viewusers" element={ <UsersList/>}/>
        <Route path="/annualreturn" element={ <AnnualReturn/>}/>   
        <Route path="/gstr1hsn" element={ <AnnualReturn formmode={'GSTR1HSN'}/>}/>  
        <Route path="/gstr1view" element={ <GSTR1View/>}/>  
        <Route path="/gstr2b" element={ <GSTR2B/>}/>  
        <Route path="/uploadpdf" element={ <UploadPdf/>}/>   
        <Route path="/smssettings" element={ <SMSSettings/>}/>   
        <Route path="/printsettings" element={ <PrintSettings/>}/>   
        {/* <Route path="/company" element={ <Company/>}/>   */}
        <Route path="/choosecompany" element={ <ChooseCompany/>}/> 
        <Route path="/contactus" element={ <Contactus/>}/>   

        <Route path="/listdeliverychalan-branchtransfer" element={ <DeliveryChalanLookup chalanId={1} />}/>
        <Route path="/listdeliverychalan-jobbased" element={ <DeliveryChalanLookup chalanId={2} />}/>
        <Route path="/installdeliverychalan-branchtransfer" element={ <DeliveryChalan chalanId={1} />}/>
        <Route path="/installdeliverychalan-jobbased" element={ <DeliveryChalan chalanId={2} />}/>

        <Route path="/installproductmaster" element={ <ProductMaster/>}/>
        <Route path="/listproductmaster" element={ <PdtMasterLookup/>}/>
        <Route path="/installproductgroup" element={ <ProductGroup/>}/>
        <Route path="/listproductgroup" element={ <PdtGroupLookup/>}/>                
               
        <Route path="/listaccledger_agent" element={ <AccLedgerLookup accgroupid={5} />}/>
        <Route path="/listaccledger_customer" element={ <AccLedgerLookup accgroupid={3} />}/>
        <Route path="/listaccledger_supplier" element={ <AccLedgerLookup accgroupid={4} />}/>
        <Route path="/listaccledger_sales" element={ <AccLedgerLookup accgroupid={8} />}/>
        <Route path="/listaccledger_tax" element={ <AccLedgerLookup accgroupid={16} />}/>
        <Route path="/accledger_agent" element={ <AccLedger accgroupid={5} />}/>
        <Route path="/accledger_customer" element={ <AccLedger accgroupid={3} />}/>
        <Route path="/accledger_supplier" element={ <AccLedger accgroupid={4} />}/>
    
        <Route path="/installtransport" element={ <Transport/>}/>
        <Route path="/listtransport" element={ <TransportLookup/>}/>
        <Route path="/installstockplace" element={ <Stockplace/>}/>
        <Route path="/liststockplace" element={ <Stockplacelookup/>}/>
        <Route path="/installcompany" element={ <Company/>}/>
        <Route path="/listcompany" element={ <CompanyLookup/>}/>
        <Route path="/listpricelist" element={ <PriceListLookup/>}/>
        <Route path="/installpricelist" element={ <PriceList/>}/>
        <Route path="/rainbowpanel" element={ <RainbowPanel/>}/>
        <Route path="/gstims" element={ <RainbowPanel/>}/>
        <Route path="/gstr2ims" element={ <GSTR2IMS/>}/>
    </Routes>
        
    </>
  );
};

export default Routers;
