import React, { useState, useEffect, useCallback } from "react";
import { NumericFormat } from 'react-number-format';
import InputMask from 'react-input-mask';
import axios from "axios";
import "./RainbowPanel.css";
import { useNavigate } from "react-router-dom";
import GSTR2IMS from "./GSTR2_IMS.jsx";
import CompanyList from '../GridLookup/CompanyList.jsx';
import { AiOutlineClose } from 'react-icons/ai';
import { RiSafariFill } from "react-icons/ri";
const RainbowPanel = ({closeModal, gstrdata }) => {
    let readData = '';
    if(gstrdata === 'IMS'){
        readData = 'Read Inward Supplies (IMS)';
    }
    let url = '';
    const Navigate = useNavigate();
    const [gstr2ims, setgstr2ims] = useState([]);
    const [formdata, setformdata] = useState({
        actcode: 0, actname: '',
        gstin: "33ADPPP0560Q1Z0", gstusername: "KOP1234.", gstpassword: "",
        email: "akalirajan67@gmail.com", emailpassword: "ajdpk5190r",
        mobile: "",
        authorisedpan: "",
        ipaddress: "",
        otp: "083569",
        txn: "dfa4cd6c800d4883a197f912ef90f609",
        connected: true,
        logintime: ""
    })

    useEffect(() => {
        readIPAddress()
    }, []);

    const readIPAddress = () => {
        axios.get('https://api.ipify.org?format=json')
            .then((response) => {
                handleSetFormData("ipaddress", response.data.ip);
            })
            .catch((error) => {
                console.error('Error fetching IP address:', error);
            });
    }

    const onEnterkeydown = (e, nextid, defselect = true) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            selectInput(nextid, defselect)
        }
    }

    const selectInput = (curid, defselect = true) => {
        const inputElement = document.getElementById(curid);
        if (defselect) {
            inputElement.select();

        }

        inputElement.focus();
    }

    const handleChange = (e) => {
        const { name, value } = e.target; // Destructure name and value from the event target
        setformdata((prevData) => ({
            ...prevData, // Spread the previous state to retain other field values
            [name]: value, // Dynamically update the field based on the name
        }));
    };

    const handleSetFormData = (name, value) => {
        setformdata((prevData) => ({
            ...prevData, // Spread the previous state to retain other field values
            [name]: value, // Dynamically update the field based on the name
        }));
    };

    const requestOTP = async () => {
        if (formdata.gstin.trim() === "") {
            alert("GSTIN should be given...")
            selectInput("gstin");
            return false;
        }

        if (formdata.gstusername.trim() === "") {
            alert("GST Portal User-Name should be given...")
            selectInput("gstusername");
            return false;
        }

        if (formdata.ipaddress.trim() === "") {
            alert("System IP Address should be given...")
            return false;
        }

        const data = {
            ipaddress: formdata.ipaddress,
            gstin: formdata.gstin.toUpperCase(),
            username: formdata.gstusername,
        }
        url = `${process.env.REACT_APP_RAINBOW_URL}/gst_requestotp`
        const res = await axios.post(url, data);
        console.log(res)
        if (res.data.result.status_cd === "1") {
            alert("OTP Send to Registered mail-id and Mobile in GST");
            handleSetFormData("txn", res.data.result.header.txn);
            selectInput("otp");
            return;
        }
        alert('Authentication Failed!');
    }

    const loginGSTPortal = async () => {
        // updateLoginData();
        if (formdata.txn.trim() === "") {
            alert("Please give Request OTP")
            return false;
        }
        if (formdata.connected) {
            alert("GST Portal already Connected")
            return true;
        }
        const data = {
            ipaddress: formdata.ipaddress,
            gstin: formdata.gstin.toUpperCase(),
            username: formdata.gstusername,
            txn: formdata.txn,
            otp: formdata.otp
        }
        url = `${process.env.REACT_APP_RAINBOW_URL}/gst_login`
        const res = await axios.post(url, data);
        console.log(res)
        if (res.data.result.status_cd === "1") {
            alert("GST Portal Successfully Connected");
            handleSetFormData("connected", true);
            updateLoginData();
            return true;
        }
        alert('Login Failed!');
        return false;
    }

    const updateLoginData = async () => {
        const data = {
            actcode: formdata.actcode,
            gstusername: formdata.gstusername,
            gstpassword: formdata.gstpassword,
            emailpassword: formdata.emailpassword,
            authorisedpan: formdata.authorisedpan.toUpperCase(),
            txn: formdata.txn,
            ipaddress: formdata.ipaddress,
            logintime: formdata.logintime
        };
        url = `${process.env.REACT_APP_RAINBOW_URL}/gst_loginupdate`
        await axios.post(url, data);
    }

    const readIMS = async () => {
        if (!formdata.connected) {
            alert("GST Portal is not Connected");
            return;
        }
        const data = {
            ipaddress: formdata.ipaddress,
            gstin: formdata.gstin,
            username: formdata.gstusername,
            txn: formdata.txn,
        }
        console.log(data);
        url = `${process.env.REACT_APP_RAINBOW_URL}/gst_readIMS`
        const res = await axios.post(url, data);
        console.log(res)
        if (res.status === 200) {
                closeModal({ gstin: formdata.gstin, imsb2b: res.data.b2b && res.data.b2b.data ? res.data.b2b.data.b2b : [], imscn: res.data.cn && res.data.cn.data ? res.data.cn.data.b2bcn : [], imsdn: res.data.dn && res.data.dn.data ? res.data.dn.data.b2bdn : []});
        }
        else{
            alert('Error Fetching Data');
        }
        console.log({ gstin: formdata.gstin, imsb2b: res.data.b2b && res.data.b2b.data ? res.data.b2b.data.b2b : [], imscn: res.data.cn && res.data.cn.data ? res.data.cn.data.b2bcn : [], imsdn: res.data.dn && res.data.dn.data ? res.data.dn.data.b2bdn : []})
    }

    const [companymodal, setCompanyModal] = useState(false);
    const handleCompanyMaster = () => {
        setTimeout(() => {
            setCompanyModal(true);
        }, [100])
    }
    const closeCompanyModal = () => {
        setCompanyModal(false);
        selectInput("gstin");
    }
    const handleCompanyRowDoubleClick = (data) => {
        const rowData = data;
        console.log(data);
        setCompanyModal(false);
        setformdata({
            ...formdata, actcode: rowData.actcode, actname: rowData.actname, gstin: rowData.gstin, gstusername: rowData.gstusername ? rowData.gstusername : '', gstpassword: rowData.gstpassword ? rowData.gstpassword : '',
            email: rowData.email ? rowData.email : '', emailpassword: rowData.emailpassword ? rowData.emailpassword : '', authorisedpan: rowData.authorisedpan ? rowData.authorisedpan : '',
            txn: rowData.txn ? rowData.txn : '', logintime: rowData.logintime ? rowData.logintime : '', mobile: rowData.mobile1 ? rowData.mobile1 : ''
        })
    };

    const handleUpdate = async () => {
        const data = {
            actcode: formdata.actcode,
            gstusername: formdata.gstusername,
            gstpassword: formdata.gstpassword,
            emailpassword: formdata.emailpassword,
            authorisedpan: formdata.authorisedpan.toUpperCase(),
        };
        url = `${process.env.REACT_APP_RAINBOW_URL}/gst_clientupdate`;
        await axios.post(url, data);
    }

    const openGSTPortal = async () => {
        const xusername = formdata.gstusername;
        const xpassword = formdata.gstpassword
        try {
            const xurl = `${process.env.REACT_APP_RAINBOW_URL}/rainbow-gst-login`
            await axios.get(xurl, {
                params: { username: xusername, password: xpassword },
            });
        } catch (error) {
            alert(error.response?.data?.error || 'Error occurred');
        }
    };

    return (
        <div className="RainbowPanel-divmain">
            <div className="RainbowPanel-main">

                <div className="RainbowPanel-left">
                    <div className="RainbowPanel-left-row">
                        <label htmlFor="gstin">GSTIN</label>
                        <InputMask
                            autoComplete='off'
                            mask="D9AAAAA9999A*Z*"
                            maskChar={null}
                            formatChars={{
                                '*': '[A-Za-z0-9]',
                                'D': '[0-3]',
                                'A': '[A-Za-z]',       // Correctly allows alphabetic characters only
                                'Z': '[Zz]',
                                '9': '[0-9]'        // Z allows only uppercase alphabets
                            }}
                            alwaysShowMask={false}
                            name="gstin" id="gstin"
                            style={{ textTransform: "uppercase" }}
                            value={formdata.gstin}
                            onChange={((e) => handleChange(e))}
                            onKeyDown={(e) => onEnterkeydown(e, "gstusername")}
                            onDoubleClick={handleCompanyMaster}
                            readOnly
                        ></InputMask>
                    </div>
                    {companymodal &&
                        (
                            <CompanyList
                                closeModal={closeCompanyModal}
                                onRowDoubleClick={handleCompanyRowDoubleClick}
                                selecteditems={formdata}
                            />
                        )}
                    <div className="RainbowPanel-left-row">
                        <label htmlFor="companyname">Company Name</label>
                        <input type="text" id="companyname" name="companyname"
                            value={formdata.actname}
                            readOnly></input>
                    </div>
                    <div className="RainbowPanel-left-row">
                        <label htmlFor="gstusername">User Name</label>
                        <input maxLength={25} id="gstusername" name="gstusername"
                            value={formdata.gstusername}
                            onChange={((e) => handleChange(e))}
                            onKeyDown={(e) => onEnterkeydown(e, "gstpassword")}></input>
                    </div>
                    <div className="RainbowPanel-left-row">
                        <label htmlFor="gstpassword">Password</label>
                        <input maxLength={25} id="gstpassword" name="gstpassword"
                            value={formdata.gstpassword}
                            onChange={((e) => handleChange(e))}
                            autoComplete="off"
                            onKeyDown={(e) => onEnterkeydown(e, "email")}></input>
                    </div>
                    <div className="RainbowPanel-left-row">
                        <label htmlFor="email">e-Mail</label>
                        <input maxLength={100} style={{ textTransform: "small" }} id="email" name="email"
                            value={formdata.email}
                            onChange={((e) => handleChange(e))}
                            onKeyDown={(e) => onEnterkeydown(e, "emailpassword")}></input>
                    </div>
                    <div className="RainbowPanel-left-row">
                        <label htmlFor="emailpassword">Password</label>
                        <input maxLength={25}
                            id="emailpassword"
                            name="emailpassword"
                            value={formdata.emailpassword}
                            onChange={((e) => handleChange(e))}
                            onKeyDown={(e) => onEnterkeydown(e, "mobile")}></input>
                    </div>
                    <div className="RainbowPanel-left-row">
                        <label htmlFor="mobile">Mobile No.</label>
                        <InputMask
                            name="mobile" id="mobile"
                            onKeyDown={(e) => onEnterkeydown(e, "authorisedpan")}
                            mask="9999999999999"
                            autoComplete='off'
                            placeholder=""
                            maskChar=""
                            value={formdata.mobile}
                            onChange={((e) => handleChange(e))}
                        />
                    </div>
                    <div className="RainbowPanel-left-row">
                        <label htmlFor="authorisedpan">Authorised PAN</label>
                        <InputMask
                            mask="AAAAA9999A"
                            maskChar={null}
                            formatChars={{
                                'A': '[A-Za-z]',       // Correctly allows alphabetic characters only
                                '9': '[0-9]'        // Z allows only uppercase alphabets
                            }}
                            style={{ textTransform: "uppercase" }}
                            alwaysShowMask={false}
                            id='authorisedpan'
                            name='authorisedpan'
                            value={formdata.authorisedpan}
                            onChange={((e) => handleChange(e))}
                            onKeyDown={(e) => onEnterkeydown(e, "ipaddress")}
                        ></InputMask>
                    </div>
                    <div className="RainbowPanel-left-row" style={{justifyContent:"space-between" }} >
                        <button style={{ marginTop: "3px" }} onClick={openGSTPortal} >Open GST Portal</button>
                        <button style={{ marginTop: "3px" }} onClick={handleUpdate} >Update</button>
                    </div>
                </div>

                <div className="RainbowPanel-right">
                    <div className="RainbowPanel-right-row">
                        <AiOutlineClose onClick={()=> closeModal({})} style={{ backgroundColor: "#ff0000", color: "white", fontSize: "16px" }} />
                    </div>
                    <div className="RainbowPanel-right-row">
                        <button style={{ width: "60%" }}
                            tabIndex={-1}
                            onClick={() => readIPAddress()}
                        >IP Address</button>
                        <InputMask
                            style={{ textAlign: "center", width: "40%" }}
                            name="ipaddress" id="ipaddress"
                            onKeyDown={(e) => onEnterkeydown(e, "requestotp", false)}
                            mask="999.999.999.999"
                            autoComplete='off'
                            placeholder=""
                            maskChar=""
                            value={formdata.ipaddress}
                            onChange={((e) => handleChange(e))}
                            readOnly={true}
                        ></InputMask>
                    </div>



                    <div className="RainbowPanel-right-row">
                        <button style={{ width: "100%" }}
                            id="requestotp"
                            name="requestotp"
                            onClick={() => requestOTP()}
                        >Request OTP</button>
                    </div>
                    <div className="RainbowPanel-right-row">
                        <label style={{ width: "100%", textAlign: "center", border: "1px solid", fontSize: "12pt", marginTop: "-4px" }}>OTP Send to Registered mail-id and Mobile in GST</label>
                    </div>

                    <div className="RainbowPanel-right-row">
                        <label style={{ width: "25%" }}>Enter OTP</label>
                        <input maxLength={6} style={{ width: "35%" }}
                            type="text"
                            id='otp'
                            name='otp'
                            value={formdata.otp}
                            onChange={((e) => handleChange(e))}
                            onKeyDown={(e) => onEnterkeydown(e, "logingstportal", false)}
                        ></input>
                        <button style={{ width: "40%" }}
                            id="logingstportal"
                            onClick={() => loginGSTPortal()}

                        >Login GST Portal</button>
                    </div>

                    <div className="RainbowPanel-right-row">
                        <label style={{ width: "25%" }}>Ref.No.</label>
                        <input maxLength={250} readOnly style={{ width: "75%" }} id="refno" name="refno" value={formdata.txn}></input>
                    </div>

                    <div className="RainbowPanel-right-row">
                        <label style={{ width: "100%", textAlign: "center", border: "2px solid" }}>Valid 6hrs only from login time</label>
                    </div>

                    <div className="RainbowPanel-right-row">
                        <button style={{ width: "100%" }}
                            id="readIMS"
                            name="readIMS"
                            onClick={() => readIMS()}
                        >{readData}</button>
                    </div>

                </div>

            </div>
        </div>
    );


}; export default RainbowPanel;