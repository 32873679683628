import React, { useState, useEffect, useRef } from 'react';
import './GSTR1View.css';
import InputMask from 'react-input-mask';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import JSZip from 'jszip';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaYoutube } from "react-icons/fa";
import { jsPDF } from 'jspdf';
// import * as XLSX from "xlsx";
import ExcelJS from "exceljs";

const AgeGridForm = () => {

    const [videoUrl, setCurrentValue] = useState('');
    const configArray = [{ id_name: 'GSTR9-Youtube URL', id_value: videoUrl }];
    const fetchConfigValue = async () => {
        try {
            const data = { configArray }
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readConfigData`, data);
            setCurrentValue(response.data[0].id_value);
        } catch (error) {
            console.error('Error fetching config value:', error);
        }
    };


    const [statesData, setStatesData] = useState([]);
    const fetchStatedata = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/api/states`);
            setStatesData(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    useEffect(() => {
        fetchConfigValue();
        fetchStatedata();
    }, []);
    let toolname = 'GSTR1 View';

    const navigate = useNavigate();

    const gridRef = useRef();
    const [gstin, setgstin] = useState('');
    const jsonInputRef = useRef();
    const excelInputRef = useRef();
    let dataobj = ['B2B', 'B2BA', 'CDNR', 'CDNRA', 'B2CS', 'B2CSA', 'HSN'];

    const [existingData, setExistingData] = useState([]);
    const [typeofdata, setTypeofData] = useState('B2B');
    const [filePrd, setFilePrd] = useState('');

    const [hsncolumnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.5,
        },
        { headerName: 'HSN/SAC', headerClass: 'custom-header', field: 'hsn_sc', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.9 },
        {
            headerName: 'Qty', headerClass: 'custom-header', field: 'qty', editable: false, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.9,
            valueFormatter: (params) => {
                if (params.node.rowPinned) {
                    return "(" + String(params.value) + ")";
                }
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        { headerName: 'UQC', headerClass: 'custom-header', field: 'uqc', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.6 },
        { headerName: 'TAX(%)', headerClass: 'custom-header', field: 'rt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        {
            headerName: 'Taxable Value', headerClass: 'custom-header', field: 'txval', editable: false, flex: 1.3, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'IGST', headerClass: 'custom-header', field: 'iamt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CGST', headerClass: 'custom-header', field: 'camt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'SGST', headerClass: 'custom-header', field: 'samt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CESS', headerClass: 'custom-header', field: 'csamt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },

    ]);

    const [b2bcdnrcolumnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.5,

        },
        { headerName: 'CTIN', headerClass: 'custom-header', field: 'ctin', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.3 },
        {
            headerName: 'Bill No', headerClass: 'custom-header', field: 'inum', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1,
            valueFormatter: (params) => {
                if (params.node.rowPinned) {
                    return "( " + String(params.value) + " )";
                }
            }
        },
        { headerName: 'Date', headerClass: 'custom-header', field: 'idt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.8 },
        { headerName: 'Inv. Type', headerClass: 'custom-header', field: 'inv_typ', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        { headerName: 'TAX(%)', headerClass: 'custom-header', field: 'rt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        {
            headerName: 'Taxable Value', headerClass: 'custom-header', field: 'txval', editable: false, flex: 1.1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'IGST', headerClass: 'custom-header', field: 'iamt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CGST', headerClass: 'custom-header', field: 'camt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'SGST', headerClass: 'custom-header', field: 'samt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CESS', headerClass: 'custom-header', field: 'csamt', editable: false, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        { headerName: 'Source', headerClass: 'custom-header', field: 'source', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.7 },
    ]);
    const [b2ccolumnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.5,
        },
        { headerName: 'State', headerClass: 'custom-header', field: 'statename', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.5 },

        { headerName: 'TAX(%)', headerClass: 'custom-header', field: 'rt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.5 },
        {
            headerName: 'Taxable Value', headerClass: 'custom-header', field: 'txval', editable: false, flex: 1.3, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'IGST', headerClass: 'custom-header', field: 'iamt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CGST', headerClass: 'custom-header', field: 'camt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'SGST', headerClass: 'custom-header', field: 'samt', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CESS', headerClass: 'custom-header', field: 'csamt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
    ]);

    const addNum = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                num: index + 1,
            };
        });
    };

    const [jsonData, setJsonData] = useState({});
    const handleImportHSN = async (event) => {
        const files = event.target.files[0];
        const fileName = files.name.toLowerCase();

        setTypeofData("B2B");

        let parsedData = null;

        try {
            if (fileName.endsWith(".json")) {
                parsedData = await readJsonFile(files);
            } else if (fileName.endsWith(".zip")) {
                parsedData = await readJsonFromZip(files);
            } else {
                console.error("Unsupported file type. Please upload a .json or .zip file.");
                return;
            }
            if (parsedData) {
                setJsonData(parsedData);
                if (parsedData?.fp) {
                    const date = parsedData.fp;
                    const fp = date.substring(0, 2) + " / " + date.substring(2);
                    setFilePrd(fp);
                }

                if (parsedData?.gstin) {
                    setgstin(parsedData.gstin);
                }
                pushData(parsedData, "B2B");
            }

        } catch (error) {
            console.error("Error processing file:", error);
            return;
        }
        jsonInputRef.current.value = '';
    };

    const isEmpty = (data) => Object.keys(data).length === 0;

    const pushData = (Data, typeofdata, forpdf = false) => {
        if (isEmpty(Data)) {
            return;
        }
        const parsedData = Data;
        let mergedData = [];
        if (typeofdata === 'HSN') {
            if (!parsedData.hsn) {
                if (forpdf === false) {
                    alert('There is no Data');
                    setExistingData([]);
                }
                return;
            }
            else {
                mergedData.push(...parsedData.hsn.data);
            }
        }
        if (typeofdata === 'B2B' || typeofdata === 'B2BA') {
            const data = typeofdata === 'B2B' ? parsedData.b2b : parsedData.b2ba;
            if (!data) {
                if (forpdf === false) {
                    alert('There is no Data');
                    setExistingData([]);
                }
                return;
            }

            data.forEach((b2bItem) => {
                b2bItem.inv.forEach((invoice) => {
                    invoice.itms.forEach((item) => {
                        const mergeData = { ...item.itm_det, ctin: b2bItem.ctin, inum: invoice.inum, idt: invoice.idt, inv_typ: invoice.inv_typ, source: invoice.srctyp ? invoice.srctyp : "--" }
                        mergedData.push(mergeData);
                    });
                });
            });
        }

        if (typeofdata === 'CDNR' || typeofdata === 'CDNRA') {
            const data = typeofdata === 'CDNR' ? parsedData.cdnr : parsedData.cdnra;
            if (!data) {
                if (forpdf === false) {
                    alert('There is no Data');
                    setExistingData([]);
                }
                return;
            }

            data.forEach((cdnrItem) => {
                cdnrItem.nt.forEach((nt) => {
                    nt.itms.forEach((item) => {
                        const mergeData = { ...item.itm_det, ctin: cdnrItem.ctin, inum: nt.nt_num, idt: nt.nt_dt, inv_typ: nt.ntty, source: nt.srctyp ? nt.srctyp : "--" }
                        mergedData.push(mergeData);
                    });
                });
            });
        }

        if (typeofdata === 'B2CS' || typeofdata === 'B2CSA') {
            const data = typeofdata === 'B2CS' ? parsedData.b2cs : parsedData.b2csa;
            if (!data) {
                if (forpdf === false) {
                    alert('There is no Data');
                    setExistingData([]);
                }
                return;
            }

            data.forEach((item) => {
                item.itms.forEach((row) => {
                    const statename = statesData.find(states => states["gstcode"] === item.pos);
                    const mergeData = { ...row, statename: statename.name + " - " + item.pos }
                    mergedData.push(mergeData);
                })
            })
        }

        const updateddata = addNum(mergedData);
        if (forpdf === false) {
            setExistingData(updateddata);
        };
        return updateddata;
    }

    // useEffect(() => {
    //     pushData(jsonData, typeofdata);
    // }, [typeofdata])

    const handleDataChange = (event) => {
        const value = event.target.value;
        setTypeofData(value);
        pushData(jsonData, value);
    }

    const calculateTotals = () => {
        let totalTaxableValue = 0, totalIGST = 0, totalCGST = 0, totalSGST = 0, totalCESS = 0;

        const nofInvoices = new Set(existingData.map((item) => item.inum));

        existingData.forEach((row) => {
            totalTaxableValue += isNaN(row.txval) ? 0 : row.txval;
            totalIGST += isNaN(row.iamt) ? 0 : row.iamt;
            totalCGST += isNaN(row.camt) ? 0 : row.camt;
            totalSGST += isNaN(row.samt) ? 0 : row.samt;
            totalCESS += isNaN(row.csamt) ? 0 : row.csamt;
        });

        return [
            {
                hsn_sc: 'Total-->',
                ctin: 'Total-->',
                qty: String(existingData.length),
                inum: String(existingData.length) + " / " + String(nofInvoices.size),
                statename: 'Total-->' + " " + "(" + String(existingData.length) + ")",
                txval: totalTaxableValue,
                iamt: totalIGST,
                camt: totalCGST,
                samt: totalSGST,
                csamt: totalCESS,
            }
        ];
    };

    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([]);

    useEffect(() => {
        const totals = calculateTotals();
        setPinnedBottomRowData(totals);
    }, [existingData]);

    const readJsonFile = async (file) => {
        try {
            const content = await file.text();
            const jsonData = JSON.parse(content);
            return jsonData;
        } catch (error) {
            console.error("Error reading JSON file:", error);
            throw error;
        }
    };
    const readJsonFromZip = async (file) => {
        try {
            const zip = await JSZip.loadAsync(file);

            const jsonPromises = Object.keys(zip.files).map(async (relativePath) => {
                const zipEntry = zip.files[relativePath];

                if (zipEntry.name.endsWith(".json")) {
                    const content = await zipEntry.async("string"); // Read the JSON file as text
                    const jsonData = JSON.parse(content); // Parse the content into JSON
                    return jsonData;
                }
            });

            // Wait for all promises to resolve and filter out undefined results
            const jsonFiles = (await Promise.all(jsonPromises)).filter(Boolean);

            if (jsonFiles.length === 0) {
                throw new Error("No JSON files found in the ZIP");
            }

            return jsonFiles.length === 1 ? jsonFiles[0] : jsonFiles;
        } catch (error) {
            console.error("Error reading ZIP file:", error);
            throw error;
        }
    };

    const getRowStyle = params => {
        if (params.node.rowPinned) {
            return { backgroundColor: "#ccc", fontWeight: "600" }
        }
    };
    const isEmptyObject = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };
    
    const handlePdfDownload = async () => {
        if(isEmptyObject(jsonData)){
            alert("Please Import GSTR-1 JSON");
            return;
        }
        const doc = new jsPDF({ orientation: "l", unit: "mm", format: "a4" });

        const [b2b, b2csa, hsn] = await Promise.all([pushData(jsonData, 'B2B', true), pushData(jsonData, 'B2CSA', true), pushData(jsonData, 'HSN', true)])
        const pageWidth = doc.internal.pageSize.width;

        const generateTable = (title, data, startY) => {
            if (!Array.isArray(data) || data.length === 0) return startY;
            if (title !== "B2B Data") {
                doc.addPage();
            }
            const columnMapping = {
                num: 'S.No.', ctin: "GSTIN", inum: 'Bill No.', idt: 'Bill Date', inv_typ: 'Type', rt: 'GST(%)',
                txval: 'Tax.Value', iamt: 'IGST', camt: 'CGST', samt: 'SGST', csamt: 'CESS', statename: 'State', hsn_sc: 'HSN/SAC',
                qty: 'Qty.', uqc: 'UQC'
            };

            let orderedColumns = [];
            let columnStyles = {};

            if (title === "B2B Data") {
                orderedColumns = ["num", "ctin", "inum", "idt", "inv_typ", "rt", "txval", "iamt", "camt", "samt", "csamt"];
                columnStyles = { 0: { halign: 'right' }, 5: { halign: 'right' }, 6: { halign: 'right' }, 7: { halign: 'right' }, 8: { halign: 'right' }, 9: { halign: 'right' }, 10: { halign: 'right' } }
            }
            if (title === "B2CSA Data") {
                orderedColumns = ["num", "statename", "rt", "txval", "iamt", "camt", "samt", "csamt"];
                columnStyles = { 0: { halign: 'right' }, 2: { halign: 'right' }, 3: { halign: 'right' }, 4: { halign: 'right' }, 5: { halign: 'right' }, 6: { halign: 'right' }, 7: { halign: 'right' } }
            }
            if (title === 'HSN Data') {
                orderedColumns = ["num", "hsn_sc", "qty", "uqc", "rt", "txval", "iamt", "camt", "samt", "csamt"];
                columnStyles = { 0: { halign: 'right' }, 2: { halign: 'right' }, 4: { halign: 'right' }, 5: { halign: 'right' }, 6: { halign: 'right' }, 7: { halign: 'right' }, 8: { halign: 'right' }, 9: { halign: 'right' } }
            }

            const headers = orderedColumns.map(key => columnMapping[key]);
            const rows = data.map(row =>
                orderedColumns.map(key => {
                    if (["txval", "iamt", "camt", "samt", "csamt"].includes(key) && typeof row[key] === "number") {
                        return row[key] === 0 ? "-" : row[key].toFixed(2);
                    }
                    return row[key] || "-";
                })
            );

            const textWidth = doc.getTextWidth(title);
            const startX = (pageWidth - textWidth) / 2;
            doc.setFontSize(12);
            doc.setFont("helvetica", "normal");
            doc.text(title, startX, startY - 5);

            doc.autoTable({
                head: [headers],
                body: rows,
                startY: startY,
                theme: "grid",
                styles: { fontSize: 10, cellPadding: 0.5 },
                columnStyles
            });
            return startY;
        };
        doc.setFontSize(10);
        let startY = 20;
        let xstr = `GSTIN: ${gstin}`;
        let textWidth = doc.getTextWidth(xstr);
        let startX = (pageWidth - textWidth) / 2;
        doc.text(xstr, startX, startY - 15);

        xstr = `File Period: ${filePrd}`;
        textWidth = doc.getTextWidth(xstr);
        startX = (pageWidth - textWidth) / 2;
        doc.text(xstr, startX, startY - 10);

        startY = generateTable("B2B Data", b2b, startY);
        startY = generateTable("B2CSA Data", b2csa, startY);
        startY = generateTable("HSN Data", hsn, startY);
        xstr = filePrd.replace("/", "_")
        doc.save(`${xstr}_${gstin.toUpperCase()}_GSTR1_RAINBOW.pdf`);
    };

    const [invoiceData, setInvoiceData] = useState([]);

    // const handleEInvoice = async (event) => {
    //     const file = event.target.files[0];
    //     if (!file) return;

    //     try {
    //         const zip = await JSZip.loadAsync(file);
    //         let b2bData = [];
    //         let cdnrData = [];

    //         const fileNames = Object.keys(zip.files).filter((name) =>
    //             name.endsWith(".xlsx") || name.endsWith(".xls")
    //         );

    //         for (let fileName of fileNames) {
    //             const fileData = await zip.files[fileName].async("arraybuffer");
    //             const workbook = XLSX.read(fileData, { type: "array" });

    //             // Function to correctly format sheet data
    //             const formatSheetData = (sheetName) => {
    //                 if (!workbook.Sheets[sheetName]) return [];

    //                 const sheet = workbook.Sheets[sheetName];

    //                 // Convert the sheet to JSON but ensure correct headers (starting from row 4)
    //                 const options = {
    //                     header: 1, // Extract as an array of arrays
    //                     raw: false,
    //                 };
    //                 const sheetData = XLSX.utils.sheet_to_json(sheet, options);

    //                 if (sheetData.length < 4) return []; // Ensure at least 4 rows exist

    //                 // Extract the correct column headers from the 4th row
    //                 const headers = sheetData[3];

    //                 // Convert the remaining rows into JSON using the extracted headers
    //                 return sheetData
    //                     .slice(4) // Process rows starting from row 5 (0-based index)
    //                     .map((row) => {
    //                         let rowObj = {};
    //                         headers.forEach((header, index) => {
    //                             if (header) rowObj[header] = row[index]; // Assign values
    //                         });
    //                         return rowObj;
    //                     })
    //                     .filter((row) => Object.values(row).some((value) => value !== undefined)); // ✅ Remove empty rows
    //             };

    //             // Process B2B sheet
    //             const b2bSheetData = formatSheetData("b2b");
    //             if (b2bSheetData.length > 0) {
    //                 b2bData = [...b2bData, ...b2bSheetData];
    //             }

    //             // Process CDNR sheet
    //             const cdnrSheetData = formatSheetData("cdnr");
    //             if (cdnrSheetData.length > 0) {
    //                 cdnrData = [...cdnrData, ...cdnrSheetData];
    //             }
    //         }
    //         console.log({ B2B: b2bData, CDNR: cdnrData })
    //         setInvoiceData({ B2B: b2bData, CDNR: cdnrData });
    //     } catch (error) {
    //         console.error("Error processing the zip file:", error);
    //     }
    // };

    const handleEInvoice = async (event) => {
        const emptyJSON = isEmptyObject(jsonData);
        if(emptyJSON){
            alert("Please Import GSTR-1 JSON");
            return;
        }
        const file = event.target.files[0];
        if (!file) return;

        try {
            const zip = await JSZip.loadAsync(file);
            let b2bData = [];
            let cdnrData = [];

            const fileNames = Object.keys(zip.files).filter((name) =>
                name.endsWith(".xlsx") || name.endsWith(".xls")
            );

            for (let fileName of fileNames) {
                const fileData = await zip.files[fileName].async("arraybuffer");

                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(fileData);

                // Function to correctly format sheet data
                const formatSheetData = (sheetName) => {
                    const sheet = workbook.getWorksheet(sheetName);
                    if (!sheet) return [];

                    const rows = sheet.getRows(5, sheet.rowCount - 4);  // Skip the first 4 rows (headers are in row 4)
                    const headers = sheet.getRow(4).values.slice(1);  // Extract headers from the 4th row

                    const sheetData = rows.map((row) => {
                        let rowObj = {};
                        headers.forEach((header, index) => {
                            rowObj[header] = row.getCell(index + 1).value;  // Assign values to the headers
                        });
                        return rowObj;
                    });

                    return sheetData.filter((row) => Object.values(row).some((value) => value !== null)); // Remove empty rows
                };

                // Process B2B sheet
                const b2bSheetData = formatSheetData("b2b");
                if (b2bSheetData.length > 0) {
                    b2bData = [...b2bData, ...b2bSheetData];
                }

                // Process CDNR sheet
                const cdnrSheetData = formatSheetData("cdnr");
                if (cdnrSheetData.length > 0) {
                    cdnrData = [...cdnrData, ...cdnrSheetData];
                }
            }
            const invData = { B2B: b2bData, CDNR: cdnrData };
            setInvoiceData(invData);
            handleComparewithGSTR1(invData);
            excelInputRef.current.value = '';
        } catch (error) {
            console.error("Error processing the zip file:", error);
        }
    };


    const handleComparewithGSTR1 = async (data) => {
        const invB2BData = data.B2B.filter((row) => Object.values(row).some((value) => value === "Valid"));
        const invCDNRData = data.CDNR.filter((row) => Object.values(row).some((value) => value === "Valid"));
        const [b2b, cdnr] = await Promise.all([pushData(jsonData, 'B2B', true), pushData(jsonData, 'CDNR', true)])
        const filteredB2BArray = invB2BData.filter(
            (item) => !b2b.some((obj) => obj.inum === item["Invoice Number"])
        );
        const filteredCDNRArray = invCDNRData.filter(
            (item) => !cdnr.some((obj) => obj.inum === item["Note Number"])
        );
        handleInvPdfDownload(filteredB2BArray, filteredCDNRArray)
    }

    const handleInvPdfDownload = async (b2b, cdnr) => {
        const doc = new jsPDF({ orientation: "l", unit: "mm", format: "a4" });
        const pageWidth = doc.internal.pageSize.width;

        const generateTable = (typeofdata, title, data, startY) => {
            if (!Array.isArray(data) || data.length === 0) return;
            if (typeofdata !== "B2B") {
                doc.addPage();
            }
            const columnMapping = {
                "S.No": 'S.No', "GSTIN/UIN of Recipient": "GSTIN", "Invoice Number": "Bill No.", "Invoice date": 'Date', "Invoice Type": 'Type', "Invoice Value": "Bill Value",
                "Note Number": 'CR/DB Note', "Note date": 'Date', "Note Type": 'Type', "Note Value": "Bill Value", "Rate": 'GST(%)',
                "Taxable Value": 'Tax.Value', "Integrated Tax": 'IGST', "Central Tax": 'CGST', "State/UT Tax": 'SGST', "Cess Amount": 'CESS', "Place Of Supply": 'State'
            };

            let orderedColumns = [];
            let columnStyles = {};

            if (typeofdata === "B2B") {
                orderedColumns = ["S.No", "GSTIN/UIN of Recipient", "Invoice Number", "Invoice date", "Invoice Type", "Invoice Value", "Rate", "Taxable Value",
                    "Integrated Tax", "Central Tax", "State/UT Tax", "Cess Amount"];
                columnStyles = { 0: { halign: 'right' }, 5: { halign: 'right' }, 6: { halign: 'right' }, 7: { halign: 'right' }, 8: { halign: 'right' }, 9: { halign: 'right' }, 10: { halign: 'right' }, 11: { halign: 'right' } }
            }
            if (typeofdata === "CDNR") {
                orderedColumns = ["S.No", "GSTIN/UIN of Recipient", "Note Number", "Note date", "Note Type", "Note Value", "Rate", "Taxable Value",
                    "Integrated Tax", "Central Tax", "State/UT Tax", "Cess Amount"];
                columnStyles = { 0: { halign: 'right' }, 5: { halign: 'right' }, 6: { halign: 'right' }, 7: { halign: 'right' }, 8: { halign: 'right' }, 9: { halign: 'right' }, 10: { halign: 'right' }, 11: { halign: 'right' } }
            }

            const headers = orderedColumns.map(key => columnMapping[key]);
            const seenInvoices = {};
            let srl = 0;
            const rows = data.map((row, index) =>
                orderedColumns.map(key => {
                    if (["Invoice Value", "Taxable Value",
                        "Integrated Tax", "Central Tax", "State/UT Tax", "Cess Amount", "Note Value"].includes(key)) {
                        return row[key] === "0.00" ? "-" : row[key];
                    }
                    if (["S.No"].includes(key)) {
                        const invnumbercol = typeofdata === 'B2B' ? "Invoice Number" : "Note Number";
                        if (seenInvoices[row[invnumbercol]]) {
                            return row[key] = "";
                        }
                        srl++;
                        seenInvoices[row[invnumbercol]] = srl;
                        return row[key] = srl; // Assign serial number
                    }
                    return row[key] || "-";
                })
            );
            const textWidth = doc.getTextWidth(title);
            const startX = (pageWidth - textWidth) / 2;
            doc.setFontSize(12);
            doc.setFont("helvetica", "normal");
            doc.text(title, startX, startY - 5);

            doc.autoTable({
                head: [headers],
                body: rows,
                startY: startY,
                theme: "grid",
                styles: { fontSize: 10, cellPadding: 0.5 },
                columnStyles
            });
            const result = { length: rows.length }
            return result;
        };

        doc.setFontSize(10);
        let startY = 20;
        let xstr = `GSTIN: ${gstin}`;
        let textWidth = doc.getTextWidth(xstr);
        let startX = (pageWidth - textWidth) / 2;
        doc.text(xstr, startX, startY - 15);

        xstr = `File Period: ${filePrd}`;
        textWidth = doc.getTextWidth(xstr);
        startX = (pageWidth - textWidth) / 2;
        doc.text(xstr, startX, startY - 10);

        let result = generateTable("B2B","B2B Data Available Excess in eInvoice", b2b, startY);
        result = generateTable("CDNR","CDNR Data Available Excess in eInvoice", cdnr, startY);
        xstr = filePrd.replace("/", "_")
        doc.save(`${xstr}_${gstin.toUpperCase()}_GSTR1_RAINBOW.pdf`);
    };

    return (
        <div className='gstr1view-divmain'>
            <div className="gstr1view-formcontainer">
                <div className='gstr1view-header'>
                    <h6>{toolname}</h6>

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <FaYoutube style={{ color: "#ff0000", fontSize: "28px", marginRight: "2px" }} onClick={() => window.open(videoUrl, "_blank", "noopener, noreferrer")} />
                        <AiOutlineClose onClick={() => navigate("/emptypage")} style={{ backgroundColor: "#ff0000", color: "white", fontSize: "18px" }} />
                    </div>
                </div>
                <div className='gstr1view-toppanel'>
                    <div className="input-group">
                        <select id='typeofdata'
                            name='typeofdata'
                            onChange={handleDataChange}
                            value={typeofdata}
                            style={{ width: "40%" }}>
                            <option value='' disabled selected>Choose</option>
                            {dataobj.map(data => (
                                <option key={data}
                                    value={data}>{data}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='file-upload-div'>
                        <label htmlFor="file-upload" style={{ paddingTop: "3px" }} className="file-upload-button" >Imp.GSTR1 JSON</label>
                        <input type="file" accept=".json, .zip" multiple={false} id="file-upload"
                            onChange={handleImportHSN} style={{ width: "0", height: "22px", display: "none" }}
                            ref={jsonInputRef} />
                    </div>

                    <div className="input-group">
                        <label>Period:</label>
                        <input type='text' id='fp' value={filePrd}
                            style={{ width: "35%" }}
                            readOnly>

                        </input>
                    </div>
                    <div className="input-group">
                        <label>GSTIN:</label>
                        <InputMask
                            autoComplete='off'
                            mask="D9AAAAA9999A*Z*"
                            maskChar={null}
                            formatChars={{
                                '*': '[A-Za-z0-9]',
                                'D': '[0-3]',
                                'A': '[A-Za-z]',
                                'Z': '[Zz]',
                                '9': '[0-9]'
                            }}
                            id='gstin'
                            alwaysShowMask={false}
                            value={gstin} onChange={(e) => setgstin(e.target.value)}
                            readOnly
                        ></InputMask>
                    </div>
                    <div className='file-upload-div'>
                        <label htmlFor="file-upload-einv" style={{ paddingTop: "3px" }} className="file-upload-button" title='Compare eInvoice Data with GSTR-1 Data And Report Excess Data in eInvoice'>Imp.eInvoice Excel</label>
                        <input type="file" accept=".xls,.xlsx,.zip" multiple={false} id="file-upload-einv" 
                            onChange={handleEInvoice} style={{ width: "0", height: "22px", display: "none" }}
                            ref={excelInputRef} />
                    </div>
                    <div className="input-group" style={{ justifyContent: 'flex-end' }}>
                        <button style={{ width: '100px' }} onClick={handlePdfDownload}>Export PDF</button>
                    </div>

                </div>
                <div className='gstr1view-container'>
                    <div className="gstr1view-agegrid ">
                        <div className="aag-theme-alpine" style={{ width: "100%", height: "100%" }}>
                            <AgGridReact
                                ref={gridRef}
                                rowHeight={25}
                                rowData={existingData}
                                columnDefs={
                                    typeofdata === 'B2B' || typeofdata === 'B2BA' ||
                                        typeofdata === 'CDNR' || typeofdata === 'CDNRA' ? b2bcdnrcolumnDefs :
                                        typeofdata === 'B2CS' || typeofdata === 'B2CSA' ||
                                            typeofdata === 'B2CL' || typeofdata === 'B2CLA' ? b2ccolumnDefs : hsncolumnDefs}
                                domLayout="normal"
                                defaultColDef={{ resizable: true, flex: 1 }}
                                pinnedBottomRowData={pinnedBottomRowData}
                                getRowStyle={getRowStyle}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgeGridForm;
